import { TFunction } from 'react-i18next';
import { get } from './generic';

export interface Transaction {
  id: string;
  datetime: string;
  name: string;
  document: string;
  currency: string;
  type: string;
  amount: string;
  transaction: string;
  quoteId: string;
  pixUUID: string;
  sellerCashierId: number;
  status: string;
  expired: boolean;
}

export const TRANSACTION_STATUS = {
  REFOUNDFAIL: 'REFOUNDFAIL',
  REFOUNDED: 'REFOUNDED',
  PROCESSED: 'PROCESSED',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
};

export async function getTransactions() {
  try {
    const { data: dataResponse } = await get('/seller/info');

    if (dataResponse.errors && dataResponse.errors.length) {
      throw new Error(dataResponse.errors[0].error);
    }

    const { seller_data: sellerData, checkout_history } = dataResponse.data
    localStorage.setItem('isInvoiceMdr', sellerData.isInvoiceMdr)
    return { sellerData, checkout_history }
  } catch (error) {
    throw new Error('');
  }
}

export function getStatusText(transaction: Transaction, t: TFunction) {
  let status =
    transaction.status === TRANSACTION_STATUS.PENDING && transaction.expired
      ? TRANSACTION_STATUS.EXPIRED
      : transaction.status;

  switch (status) {
    case TRANSACTION_STATUS.APPROVED:
      return t('success');
    case TRANSACTION_STATUS.REFOUNDFAIL:
      return t('fail');
    case TRANSACTION_STATUS.EXPIRED:
      return t('fail');
    case TRANSACTION_STATUS.PENDING:
      return t('pending');
    case TRANSACTION_STATUS.PROCESSED:
      return t('processed');
  }
}

import { Box } from '@mui/material';
import { FullMenu } from '../../Menu/FullMenu';
import { useMenuContext } from '../../../contexts/MenuContext';

export type LeftPanelProps = {
  opened: boolean
  handleMenuAction: () => void
  width?: string | number
};

function LeftPanel({ opened, handleMenuAction, width }: LeftPanelProps) {
  const { visibleLayout } = useMenuContext()
  return (
    <Box
      sx={{
        display: {
           xs: visibleLayout === 'flex' && opened ? visibleLayout : 'none',
           sm: visibleLayout === 'flex' && opened ? visibleLayout : 'none',
           md: visibleLayout === 'flex' ? 'inline' : 'none',
           lg: visibleLayout === 'flex' ? 'inline' : 'none' },
        position: 'absolute',
        zIndex: 2,
        width,
        height: '100vh',
        borderRight: '1px solid #ccc',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width,
        },
        backgroundColor: 'primary.contrastText',
        color: 'white',
        '::-webkit-scrollbar': {
          width: '10px',
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'primary.contrastText',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#FFF',
          borderRadius: '4px',
        },
        transition: 'width .3s ease-in-out, display .2s ease-in-out'
      }}
    >
      <Box
        sx={{
          overflow: '',
          paddingTop: opened ? 6.7 : 8.7
        }}
      >
        <FullMenu handleMenuAction={handleMenuAction} opened={opened} />
      </Box>
    </Box>
  );
}

export default LeftPanel;

import { get, post, put } from './generic';

export interface QuoteCreateRequest {
  amount: number;
  currency_id: number;
  transaction: string;
}

export interface QuoteCreateResponse {
  id: string;
  currency: string; //'USDBRL'
  quote: string; // '4.9209'
  ts: string; // '2022-06-01 16:21:46'
  side: string; // 'buy'
  preview_receipt: {
    FGNquantity: number; // 8700.0
    BRLquantity: number; // to pix generation
    iof: number;
    fees: number;
    vet: number;
  };
}

export interface QuoteSimulateResponse {
  id: string;
  currency: string; //'USDBRL'
  quote: string; // '4.9209'
  ts: string; // '2022-06-01 16:21:46'
  side: string; // 'buy'
  mdr: number | undefined;
  preview_receipt: {
    FGNquantity: number; // 8700.0
    BRLquantity: number; // to pix generation
    iof: number;
    fees: number;
    vet: number;
  };
  credit_card: {
    FGNquantity: number;
    BRLquantity: number;
    iof: number;
    fees: number;
    vet: number;
  };
}

export interface QuoteConsultResponse {
  FGNquantity: number;
  BRLquantity: number;
  quote: number;
  iof: number;
  mdr: number;
  fee: number;
}

export interface QuoteActivateRequest {
  id: string;
}

export interface QuoteActivateResponse {
  id: string;
  activated: true;
}

export class TransactionLimitError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, TransactionLimitError.prototype);
  }
}

export async function createQuote(
  data: QuoteActivateRequest,
): Promise<QuoteActivateResponse> {
  try {
    const res = await post('/quotation/activate', data);

    if (res.data.errors && res.data.errors.length) {
      throw new Error(res.data.errors[0].error);
    }

    return res.data.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export async function simulateQuote(
  data: QuoteCreateRequest,
): Promise<QuoteSimulateResponse> {
  try {
    const res = await put('/quotation/simulate', data);

    if (res.data.errors && res.data.errors.length) {
      if (res.data.errors[0].status === 406) throw new TransactionLimitError();
      throw new Error(res.data.errors[0].error);
    }

    return res.data.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export async function consultQuotation(quoteId: string): Promise<QuoteConsultResponse> {
  try {
    const res = await get(`/quotation/consult/${quoteId}`);

    if (res.data.errors && res.data.errors.length) {
      if (res.data.errors[0].status === 406) throw new TransactionLimitError();
      throw new Error(res.data.errors[0].messages);
    }
    return res.data.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

import { Dialog, DialogContent, Box } from "@mui/material"
import H2 from "../H2"
import H3 from "../H3"
import PrimaryButton from "../PrimaryButton"
import DefaultButton from "../DefaultButton"
import { useNavigate } from "react-router-dom"
import { HOME_PAGE_PATH } from "../../utils/constants"
import { useTranslation } from "react-i18next"

type ModalToCancelOperationProps = {
  open: boolean
  onClose: () => void
}

export const ModalToCancelOperation = ({ open, onClose }: ModalToCancelOperationProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation('qrcode')
  return (
    <Dialog open={open} onClose={onClose} aria-label="tested-id">
      <DialogContent sx={{
        my: '2rem',
        mx: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '.625rem',
        borderRadius: '.75rem',
        width: '317px'
      }}>
        <Box
          component="img"
          src="/exit-operation.svg"
          width="140px"
          height="83px"
        />
        <H2 sx={{
          fontSize: '1.5rem',
          fontWeight: '400',
          lineHeight: '2rem',
          color: 'rgba(0,0,0,0.8)'
        }}>
          {t('titleExitModal')}
        </H2>
        <H3
          sx={{
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.375em',
            color: '#5f5f5f'
          }}>
          {t('descriptionExitModal')}
        </H3>
        <PrimaryButton variant="contained" fullWidth onClick={onClose} sx={{
          my: '1rem'
        }}>{t('continueExitModal')}</PrimaryButton>
        <DefaultButton variant="text" sx={{
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.375em',
          border: 'none',
          background: 'transparent',
          color: '#590029',
        }}
          onClick={() => navigate(HOME_PAGE_PATH)}>{t('exitTextModal')}</DefaultButton>
      </DialogContent>
    </Dialog>
  )
}
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { doRefresh } from '../../services/auth';
import { Currency, getCurrencies } from '../../services/currency';
import { getSellerData } from '../../services/seller';

interface SellerInfo {
  id: number;
  email: string;
  name: string;
  currencies: Currency[];
}

interface SellerContextType {
  sellerInfo: SellerInfo;
  setSellerInfo: Dispatch<SetStateAction<SellerInfo>>;
  currencies: Currency[] | undefined;
  loadSellerInfo: () => void;
}

const defaultValues: SellerInfo = {
  id: 0,
  email: '',
  name: '',
  currencies: [],
};

export const SellerInfoContext = React.createContext<SellerContextType>({
  sellerInfo: defaultValues,
  setSellerInfo: () => {},
  currencies: undefined,
  loadSellerInfo: () => {}
});

export const useSellerInfo = () => useContext(SellerInfoContext);

type Props = {
  children: React.ReactNode;
};

export const SellerInfoProvider = (props: Props): ReactElement => {
  const [sellerInfo, setSellerInfo] = useState<SellerInfo>(defaultValues);
  const [currencies, setCurrencies] = useState<Currency[]>();
  const navigate = useNavigate();

  async function loadCurrencies() {
    try {
      const data = await getCurrencies();
      setCurrencies(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadSellerInfo() {
    try {
      const data = await getSellerData();
      setSellerInfo(data);
    } catch (error) {
      navigate('/entrar');
    }
  }

  async function refreshToken() {
    try {
      await doRefresh();
    } catch (error) {
      navigate('/entrar');
    }
  }

  useEffect(() => {
    loadSellerInfo();
    const interval = setInterval(refreshToken, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SellerInfoContext.Provider
      value={{ sellerInfo, setSellerInfo, currencies, loadSellerInfo }}
    >
      {props.children}
    </SellerInfoContext.Provider>
  );
};

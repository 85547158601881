import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SaleContext } from '../../contexts/SaleContext';
import { validateCPF } from '../../services/user';
import { maskCpf } from '../../utils/masks';
import { checkCPF } from '../../utils/validations';
import PrimaryButton from '../../components/PrimaryButton';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';
import { CUSTOMER_REGISTER_PAGE_PATH, QRCODE_PAGE_PATH, SALES_PAGE_PATH } from '../../utils/constants';
import GoBack from '../../components/GoBack';
import { useLimit } from '../../hooks/useLimit';
import Limit from './Limit';
import { useModalState } from '../../hooks/useModalState';
import { useSnack } from '../../components/SnackProvider';

interface SaleCpfCheckProps {
  onClose: (error?: string) => void;
}

const SaleCPFCheck = ({ onClose }: SaleCpfCheckProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('sale');
  const [cpf, setCPF] = useState<string>('');
  const [errorCPF, setErrorCPF] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { saleData, setSaleData } = useContext(SaleContext);

  /**
   * @author Angelo Reis <angelo.reis@3rd.braza.com.br>
   * @desciption Adicionado uso do hooks criados para controlar o limite diário 
   * e anual. E adicionado um erro genérico quando for tentar uma nova tentativa.
   * Assim o usuário é forçado a começar uma nova cotação!   * 
   * 
   * @DataModificacao 23.05.2023
   * @task PAGA-746 - Ajustes de Navegação
   */
  const { isOpenModal, handleModalState } = useModalState()
  const { limitDaily, limitYear, handleLimitDairy, handleLimitYear } = useLimit(true)

  const { genericError } = useSnack()

  const onCloseBothLimitModal = () => {
    handleLimitDairy(true)
    handleLimitYear(true)
    handleModalState(false)
  }

  const handleCPFChange = (value: string) => {
    value = maskCpf(value);
    setCPF(value);

    if (!value) {
      setErrorCPF(false);
      return;
    }

    if (value.length === 14) {
      validateCPFInput(value);
    }
  };

  /**
   * @author Angelo Reis <angelo.reis@3rd.braza.com.br>
   * @desciption Rotina assincrona para verificar e validar o cpf do usuário
   * neste momento o usuário realizou a cotação e a partir desse ponto vai efetuar 
   * a compra/câmbio através do pix, entretanto, o cpf precisa ser validado para as situações
   * que estão presente no condicional dentro do try.
   * 
   * @returns redireciona ou ativa algum snack/messagem de erro ao usuário para
   * que seja feita uma nova cotação.
   * 
   * @DataModificacao 23.05.2023
   * @task PAGA-746 - Ajustes de Navegação
   */
  const validateCPFOnBack = async () => {
    if (!saleData.quote) {
      return;
    }

    setLoading(true);
    try {
      const { blocked, daily_limit, founded, has_limit, is_valid } = await validateCPF(cpf, saleData.quote.id);

      setLoading(false);
      if (
        (is_valid === false && founded === false) ||
        blocked
      ) {
        setErrorCPF(true);
        return;
      }

      if (founded === false) {
        setSaleData({ ...saleData, document: cpf });
        navigate(CUSTOMER_REGISTER_PAGE_PATH);
        return;
      }

      if (daily_limit === false) {
        handleModalState()
        handleLimitDairy(daily_limit)
        return;
      }

      if (has_limit === false) {
        handleLimitYear(has_limit)
        handleModalState()
        return;
      }

      if (daily_limit === false && has_limit === false) {
        handleLimitYear(has_limit)
        handleLimitDairy(daily_limit)
        handleModalState()
        return;
      }

      setSaleData({ ...saleData, document: cpf });
      navigate(QRCODE_PAGE_PATH);
    } catch (e: any) {
      if (e.message) {
        genericError();
        setLoading(false);
        return;
      } else if (e.response.status === 422) {
        setSaleData({ ...saleData, document: cpf });
        navigate(CUSTOMER_REGISTER_PAGE_PATH);
      } else {
        setErrorCPF(true);
      }
      setSaleData({ ...saleData, document: cpf });
      navigate(QRCODE_PAGE_PATH);
    }
    setLoading(false);
  };

  const validateCPFInput = (cpf: string) => {
    if (!checkCPF(cpf)) {
      setErrorCPF(true);
      return false;
    }
    setErrorCPF(false);
    return true;
  };

  const handleSubmit = () => {
    if (!validateCPFInput(cpf)) {
      return;
    }

    validateCPFOnBack();
  };

  return (
    <>
      <Box sx={{ mt: '4rem', pl: '8rem', width: '100%', alignItems: 'flex-start' }}>
        <GoBack to={() => navigate(SALES_PAGE_PATH)} />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box>
          <Box
            sx={{
              display: 'flex',

            }}
          >
            <Box
              sx={{
                mt: '4rem',
                maxWidth: '300px',
              }}
            >
              <Typography
                color="primary"
                fontWeight="700"
                fontSize="1.125rem"
                mt="1rem"
                textAlign="left"
              >
                {t('cpf')}
              </Typography>
              <Box mt="1.875rem">
                <TextField
                  error={errorCPF}
                  fullWidth
                  label="CPF"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={loading}
                  inputProps={{ maxLength: 14 }}
                  helperText={
                    <Box component="small" fontSize="0.75rem" color="error">
                      {errorCPF ? t('invalid') : <>&nbsp;</>}
                    </Box>
                  }
                  name="cpf"
                  onChange={(e) => handleCPFChange(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === 'Enter' && handleSubmit();
                  }}
                  value={cpf}
                />
                <PrimaryButton
                  variant="contained"
                  fullWidth
                  sx={{ mt: '2rem' }}
                  disabled={errorCPF || loading}
                  onClick={handleSubmit}
                >
                  {!loading ? t('next') : <CircularProgress color="inherit" />}
                </PrimaryButton>
                {
                  isOpenModal &&
                  <>
                    <Limit open={limitDaily && isOpenModal} onClose={onCloseBothLimitModal} message='Limite diário de R$ 10.000,00 atingido' />
                    <Limit open={limitYear && isOpenModal} onClose={onCloseBothLimitModal} message='Limite Anual de R$ 100.000,00 atingido' />
                    <Limit open={limitYear && limitDaily && isOpenModal} onClose={onCloseBothLimitModal} message='Os limites diários e anuais foram atingidos' />
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <TermsAndPrivacy />
    </>
  );
};

export default SaleCPFCheck;

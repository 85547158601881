import { useState } from "react"

/**
 * @author Angelo Reis <angelo.reis@3rd.braza.com.br>
 * @desciption Adicionado uso do hooks criados para controlar o modal de limite diário 
 * e anual. 
 * 
 * @DataModificacao 23.05.2023
 * @task PAGA-746 - Ajustes de Navegação
 */

export const useModalState = (isOpen: boolean = false) => {
  const [isOpenModal, setIsOpenModal] = useState(isOpen)
  
  const handleModalState = (isOpen: boolean = true) => {
    setIsOpenModal(isOpen)
  }

  return {
    isOpenModal,
    handleModalState
  }
}
import { useContext } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import GoBack from '../../components/GoBack';
import H2 from '../../components/H2';
import PrimaryButton from '../../components/PrimaryButton';
import { RegisterContext } from '../../contexts/RegisterContext';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';

type TermsAcceptanceProps = {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
};

const TermsAcceptance = (props: TermsAcceptanceProps) => {
  const { open, onClose, onNext } = props;
  const { registerData } = useContext(RegisterContext);

  return (
    <Drawer anchor="right" open={open} sx={{ zIndex: '9999' }}>
      <Box
        sx={{
          background: 'white',
          px: '20px',
          py: '2.5rem',
          width: { xs: '100vw', sm: '38.3125rem' },
        }}
      >
        <GoBack to={onClose} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx: 'auto',
            mt: '2.5rem',
            maxWidth: '390px',
          }}
        >
          <H2>{'Declaração de endereço'}</H2>
          <Typography
            sx={{ textJustify: 'inter-word', textAlign: 'justify' }}
            color="text.pimary"
            fontSize="16px"
            fontWeight={400}
            mt="1rem"
          >
            {`É necessário colocar um endereço no Brasil. Para continuar, 
                concorde com esta autodeclaração de endereço:`}
          </Typography>
          <Typography
            sx={{ textJustify: 'inter-word', textAlign: 'justify' }}
            color="text.pimary"
            fontSize="16px"
            fontWeight={400}
            mt="1.5rem"
          >
            {`Eu, ${registerData.name}, portador(a) do CPF
                ${registerData.document}, DECLARO para fins de comprovação
                de residência, sob as penalidades da Lei (artigo 2 da Lei
                7115/83) que sou residente e domiciliado(a) em:`}
          </Typography>
          <List>
            <ListItem sx={{ mt: '1.5rem', p: '0rem' }}>
              <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                <CheckIcon color="success" />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    color="text.primary"
                    fontSize="1rem"
                    variant="body2"
                  >
                    <strong>Endereço:</strong> {registerData.street},{' '}
                    {registerData.number}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ p: '0rem' }}>
              <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                <CheckIcon color="success" />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    color="text.primary"
                    fontSize="1rem"
                    variant="body2"
                  >
                    <strong>Cidade:</strong> {registerData.city}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ p: '0rem' }}>
              <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                <CheckIcon color="success" />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    color="text.primary"
                    fontSize="1rem"
                    variant="body2"
                  >
                    <strong>Estado:</strong> {registerData.state}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ p: '0rem' }}>
              <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                <CheckIcon color="success" />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    color="text.primary"
                    fontSize="1rem"
                    variant="body2"
                  >
                    <strong>CEP:</strong> {registerData.zipCode}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Typography
            sx={{ textJustify: 'inter-word', textAlign: 'justify' }}
            color="text.primary"
            fontSize="1rem"
            mt="1.5rem"
          >
            {`Declaro ainda que estou ciente de que a falsificação desta
                declaração pode implicar em sanções penais, conforme previsto no
                artigo 299 do Código Penal.`}
          </Typography>
          <PrimaryButton
            fullWidth
            onClick={onNext}
            sx={{ maxWidth: '20rem', mt: '2.5rem' }}
            variant="contained"
          >
            {'Concordar e continuar'}
          </PrimaryButton>
          <Box mt="0.9375rem" mx="auto">
            <TermsAndPrivacy />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default TermsAcceptance;

import axios from 'axios';

export const getCepInfo = async (cep: string) => {
  const baseURL = 'https://viacep.com.br/ws'
  const requestCep = baseURL.concat('/').concat(cep).concat('/json')
  try {
    const res = await axios.get(requestCep);
    return res.data;
  } catch (error) {
    throw new Error('');
  }
};
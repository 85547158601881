import { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next';
import {
  formatDuration,
  maskCurrency,
  maskSohNumeros,
} from '../../utils/masks';
import {
  createPix,
  PixCreateRequest,
  PixCreateResponse,
  verifyPix
} from '../../services/pix';
import { SaleContext } from '../../contexts/SaleContext';
import { useSellerInfo } from '../../components/SellerInfo';
import { getCurrencySimbol } from '../../utils/currency';
import { DetailsSaleModal } from '../../components/DetailsSaleModal';
import DefaultButton from '../../components/DefaultButton';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ModalToCancelOperation } from '../../components/ModalCancelOperation';

interface CodeProps {
  onError: (error?: string) => void;
  onSuccess: () => void;
  onCode: (code: PixCreateResponse) => void;
}

function Code({ onError, onSuccess, onCode }: CodeProps) {
  const { t } = useTranslation('qrcode');
  const { saleData, setSaleData } = useContext(SaleContext);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false)
  const [valueBr, setValueBr] = useState<number>(0);
  const [valueLocal, setValueLocal] = useState<number>(0);
  const [cambio, setCambio] = useState<number>(0);
  const [custo, setCusto] = useState<number>(0);
  const [iof, setIof] = useState<number>(0);
  const [expirationTime, setExpirationTime] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [qrcodePath, setQRCodePath] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');
  const { sellerInfo } = useSellerInfo();
  const [pix, setPix] = useState<PixCreateResponse>();
  const [pixDateTime, setPixDateTime] = useState<string>('');
  const [errorPix, setErrorPix] = useState<boolean>(false)

  const expirationTimeRef = useRef(0);
  expirationTimeRef.current = expirationTime;

  const qrcodePathRef = useRef('');
  qrcodePathRef.current = qrcodePath;

  const pixRef = useRef<PixCreateResponse>();
  pixRef.current = pix;

  const handleOpenModalDetail = () => {
    setIsOpenModal(true)
  }

  const handleCloseModalDetail = () => {
    setIsOpenModal(false)
  }

  const handleOpenModalCancelOperation = () => setIsOpenCancelModal(true)
  const handleCloseModalCancelOperation = () => setIsOpenCancelModal(false)

  useEffect(() => {
    verifyPayment();
  }, [timeLeft]);

  async function verifyPayment() {
    if (!pixRef.current) {
      return;
    }

    try {
      const res = await verifyPix(saleData.quote?.id || '');
      if (res.pix_status !== null) {
        if (res.pix_status === 'paid') {
          const newSaleData = {
            ...saleData,
            confirmationDatetime: new Date().toLocaleString(),
          };
  
          setSaleData(newSaleData);
          onSuccess();
        } else if (res.pix_status.toLowerCase() === 'doc_mismatch') {
          onError('mismatch');
        } else if (res.pix_status.toLowerCase() === 'amount_mismatch') {
          onError('amountMismatch');
        }
      }      
    } catch (error) {
      onError();
    }
  }

  async function getQRCode() {
    setErrorPix(false)
    if (!saleData.quote || !saleData.simulated) {
      return;
    }

    const data: PixCreateRequest = {
      quotation_id: saleData.quote.id,
      cpf: maskSohNumeros(saleData.document),
    };

    try {
      const code = await createPix(data);

      setPix(code);
      setCambio(parseFloat(saleData.simulated.quote));
      setIof(saleData.simulated.preview_receipt.iof);

      setQRCodePath(code.image_url);
      setValueBr(saleData.simulated.preview_receipt.BRLquantity);
      setValueLocal(saleData.simulated.preview_receipt.FGNquantity);
      setCusto(saleData.simulated.preview_receipt.fees);
      setCurrency(saleData.currency);

      setPixDateTime(new Date().toLocaleString());

      setExpirationTime(new Date(code.expire_at).getTime());

      const newSaleData = {
        ...saleData,
        pix: code,
        pixDatetime: new Date().toLocaleString(),
      };

      setSaleData(newSaleData);

      onCode(code);
    } catch (error: any) {
      if (error.toLocaleString().includes('500') || error.toLocaleString().includes('503')) {
        setErrorPix(true)
        setQRCodePath('')
        return
      }

      onError();
    }
  }

  function tick() {
    if (!qrcodePathRef.current) {
      return;
    }

    let ttl = expirationTimeRef.current - new Date().getTime();
    if (ttl <= 0) {
      onError('expired');
      return;
    }

    setTimeLeft(ttl);
  }

  const QRCodeShow = useCallback(() => {
    if (errorPix) {
      return (
        <DefaultButton variant='contained' onClick={getQRCode} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '151px',
          height: '151px',
          color: '#000000',
          backgroundColor: '#ffffff',
          borderColor: 'rgb(255, 243, 246, .6)',
          fontSize: '1rem',
          '&:hover': {
            backgroundColor: 'rgb(220, 196, 203, .7)',
            borderColor: 'rgb(255, 243, 246, .6)'
          }
        }}>
          <CachedRoundedIcon fontSize='large' />
          Gerar QR Code
          Novamente
        </DefaultButton>
      )
    }

    return (
      !errorPix && qrcodePath ? (
        <Box
          component="img"
          sx={{
            width: '151px',
          }}
          src={qrcodePath}
        />
      ) : (
        <Stack gap={4} alignItems='center'>
          <Skeleton variant='rectangular' width='151px' height='151px' />
        </Stack>
      )
    )
  }, [qrcodePath, errorPix])

  useEffect(() => {
    getQRCode();

    const interval = setInterval(tick, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <Box sx={{ textAlign: 'center', width: '100%', mb: '10px', mt: '3rem' }}>
        <Box
          component="img"
          sx={{ display: 'none', mx: 'auto', width: '125px', '@media print': { display: 'block' }, }}
          src="/braza-logo-center-black.svg"
          alt="Braza"
        />
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          color: 'text.secondary',
          '@media print': { color: '#000000' },
          fontWeight: '700',
          fontSize: '24px',
          mb: '30px',
          pb: '8px',
          borderBottom: '1px solid rgba(211, 211, 211, 0.5);',
        }}
      >
        {t('title')}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: '1.625rem',
        }}
      >
        {valueBr ? (
          <><Typography
            sx={{
              fontSize: '52px',
              fontWeight: 800,
              textAlign: 'center',
              '@media print': { color: '#000000' },
            }}
          >
            <Box
              component="span"
              sx={{
                fontSize: '24px',
                fontWeight: 800,
                verticalAlign: 'middle',
                '@media print': { color: '#000000' },
              }}
            >
              {'R$'} &nbsp;
            </Box>
            {maskCurrency(valueBr.toFixed(2))}
          </Typography>
            <Typography
              sx={{
                textAlign: 'right',
                color: 'text.secondary',
                '@media print': { color: '#000' },
              }}
            >
              {`(${getCurrencySimbol(
                sellerInfo.currencies,
                currency,
              )} ${maskCurrency(valueLocal.toFixed(2))})`}
            </Typography>
          </>) : (
          <Stack gap={4} alignItems='center'>
            <Skeleton variant='rectangular' width={210} height={40} />
            <Skeleton variant='rectangular' width={120} height={20} />
          </Stack>
        )}

      </Box>
      <Box sx={{ textAlign: 'center', mb: '20px' }}>
        <Tooltip title={'Para mais detalhes da operação, clique aqui'}>
          <Typography onClick={handleOpenModalDetail} sx={{
            fontSize: '.937rem',
            fontWeight: '600',
            color: '#590029',
            cursor: 'pointer',
            textDecorationLine: 'underline',
            '@media print': { display: 'none' }
          }}>
            {t('seeDetails')}
          </Typography>
        </Tooltip>
        <DetailsSaleModal
          isOpenDialog={isOpenModal}
          onCloseModal={handleCloseModalDetail}
          cambio={cambio}
          iof={iof}
          custo={custo} />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center', mb: '20px'
      }}>
        <QRCodeShow />
        <Box
          sx={{
            '@media print': { display: 'none' },
          }}
        >
          {timeLeft ? (
            <>
              <Box component="span" sx={{ color: 'text.secondary' }}>
                {t('timeLeft')}
              </Box>
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {formatDuration(timeLeft)}
              </Box>
            </>) : (
            <Stack gap={4} alignItems='center' pt={'.875rem'}>
              <Skeleton variant='rectangular' width={120} height={20} />
            </Stack>
          )}
          <Box sx={{ textAlign: 'center', my: '20px' }}>
            <Tooltip title={'Para cancelar a compra clique aqui'}>
              <Typography onClick={handleOpenModalCancelOperation} sx={{
                fontSize: '1rem',
                fontWeight: '400',
                color: '#590029',
                cursor: 'pointer',
                '@media print': { display: 'none' }
              }}>
                {t('cancelOperation')}
              </Typography>
            </Tooltip>
            <ModalToCancelOperation open={isOpenCancelModal} onClose={handleCloseModalCancelOperation} />
          </Box>
          <Box sx={{
            display: 'flex',
            alignContent: 'center',
            gap: '12px',
            mt: '1rem',
            py: '1rem',
            px: '2rem',
            borderRadius: '6px',
            background: '#F7F7F7',
            color: 'rgba(0,0,0,0.8)'
          }}>
            <InfoOutlined fill='rgba(0,0,0,0.8)' />
            <Typography color='rgba(0,0,0,0.8)'>Somente são aceitos Pix de mesma titularidade</Typography>
          </Box>
        </Box>
      </Box>
      {pix ? (
        <Box
          sx={{
            borderTop: '1px solid rgba(211, 211, 211, 0.5);',
            textAlign: 'center',
            fontSize: '12px',
            color: 'text.secondary',
            mt: '20px',
            pt: '20px',
            '@media print': { color: '#000' },
          }}
        >
          <Box>
            {t('Número da transação')}: {pix?.transaction}
          </Box>
          <Box>
            {t('Cliente')}: {pix?.name}
          </Box>
          <Box>
            {t('CPF')}: {pix?.doc}
          </Box>
          <Box>{pixDateTime}</Box>
        </Box>
      ) : (
        <Stack gap={4} alignItems='center'>
          <Skeleton variant='rectangular' width={310} height={60} />
        </Stack>
      )}
    </Box>
  );
}

export default Code;

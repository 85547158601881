import { ReactElement, ReactNode, createContext, useContext, useState } from "react";

interface GeneralConfigContextType {
  invoicePartnerEnable: boolean
  handleVisbilityInvoicePartner: (visible: boolean) => void,
}

export const GeneralConfigContext = createContext<GeneralConfigContextType>({
  invoicePartnerEnable: false,
  handleVisbilityInvoicePartner: () => { },
})

type Props = {
  children: ReactNode;
};

export const GeneralSellerConfigProvider = ({ children }: Props): ReactElement => {
  const [invoicePartnerEnable, setInvoicePartnerEnable] = useState<boolean>(false)

  const handleVisbilityInvoicePartner = (visible: boolean) => setInvoicePartnerEnable(visible)
  
  return (
    <GeneralConfigContext.Provider value={{invoicePartnerEnable, handleVisbilityInvoicePartner}}>
      { children }
    </GeneralConfigContext.Provider>

  )
}

export const useGeneralConfigSeller = () => useContext(GeneralConfigContext)
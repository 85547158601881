import React, { createContext, ReactElement, useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type SnackType = 'success' | 'info' | 'warning' | 'error';

export interface SnackInfo {
  alert: (value: string) => void;
  info: (value: string, durantion?: number) => void;
  success: (value: string) => void;
  error: (value: string) => void;
  genericError: () => void;
}

const SnackInfoDefault: SnackInfo = {
  alert: (value: string) => { },
  info: (value: string, durantion?: number) => { },
  success: (value: string) => { },
  error: (value: string) => { },
  genericError: () => { },
};

interface Props {
  children: React.ReactNode;
}

const SnackContext = createContext<SnackInfo>(SnackInfoDefault);

export function useSnack() {
  return useContext(SnackContext);
}

export function SnackProvider(props: Props): ReactElement {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<SnackType>('info');
  const [duration, setDuration] = useState(6000);
  const { t } = useTranslation()

  function alert(value: string, duration?: number) {
    setMessage(value);
    setType('warning');
    duration && setDuration(duration);
    setOpen(true);
  }

  function success(value: string, duration?: number) {
    setMessage(value);
    setType('success');
    duration && setDuration(duration);
    setOpen(true);
  }

  function info(value: string, duration?: number) {
    setMessage(value);
    setType('info');
    duration && setDuration(duration);
    setOpen(true);
  }

  function error(value: string, duration?: number) {
    setMessage(value);
    setType('error');
    duration && setDuration(duration);
    setOpen(true);
  }

  function genericError() {
    setMessage(t('failTryAgain'));
    setType('error');
    setOpen(true);
  }

  return (
    <SnackContext.Provider value={{ alert, success, info, error, genericError }}>
      {props.children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={duration}
        onClose={() => setOpen(false)}
        sx={{
          zIndex: 9999
        }}
      >
        <Alert variant="filled" severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </SnackContext.Provider>
  );
}

export default SnackContext;

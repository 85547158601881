import { useContext, useEffect, useState } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { maskCurrency } from '../../utils/masks';
import { SaleContext } from '../../contexts/SaleContext';

function Simulation() {
  const { t } = useTranslation('simulation');
  const { saleData } = useContext(SaleContext);
  const [valuePix, setValuePix] = useState('');
  const [valueCC, setValueCC] = useState('');
  const [saveValue, setSaveValue] = useState('');
  const [savePct, setSavePct] = useState('');

  useEffect(() => {
    if (!saleData) {
      return;
    }

    const valuecc = saleData.simulated?.credit_card.BRLquantity ?? 0;
    setValueCC(valuecc?.toFixed(2));

    const valuepix = saleData.simulated?.preview_receipt?.BRLquantity ?? 0;
    setValuePix(maskCurrency(valuepix?.toFixed(2)));

    setSaveValue(maskCurrency((valuecc - valuepix).toFixed(2)));
    setSavePct(Math.round((100 * (valuecc - valuepix)) / valuecc).toString());
  }, [saleData]);
  return (
    <Box
      sx={{
        py: '48px',
        px: '28px',
        background: '#ffffff'
      }}
    >
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '24px',
          color: 'text.primary',
        }}
      >
        {t('payLess')}
      </Box>
      <Box
        sx={{
          fontWeight: 300,
          fontSize: '14px',
          color: 'text.secondary',
        }}
      >
        {t('simText')}
      </Box>
      <Paper
        elevation={3}
        sx={{
          background: '#ffffff',
          borderRadius: '10px',
          mt: '36px',
          mb: '8px',
          py: '20px',
          px: '24px',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '19px',
              lineHeight: '30px',
              color: 'text.primary',
            }}
          >
            {t('pix')}
          </Box>
          <Box
            component="span"
            sx={{
              backgroundColor: 'success.main',
              borderRadius: '6px',
              color: '#fff',
              fontWeight: 600,
              fontSize: '12px',
              p: '2px',
              px: '8px',
              mt: '6px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <StarIcon
              sx={{
                color: '#FFDA44',
                fontSize: 'small',
                mr: '5px'
              }}
            />
            {t('price')}
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '24px',
          }}
        >
          <Box>{'IOF (0,38%)'}</Box>
          <Box>{`R$ ${saleData.simulated?.preview_receipt?.iof}`}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '24px',
          }}
        >
          <Box>{'Nosso Custo'}</Box>
          <Box>{`R$ ${saleData.simulated?.preview_receipt?.fees}`}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            fontWeight: 800,
            fontSize: '20px',
            color: 'text.primary',
            lineHeight: '30px',
          }}
        >
          <Box>{'Total'}</Box>
          <Box>
            {'R$ '}
            {valuePix}
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            color: '#3CC13B',
            lineHeight: '20px',
          }}
        >
          <Box>{''}</Box>
          <Box>{t('save')}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            fontWeight: 800,
            fontSize: '19px',
            color: '#3CC13B',
            lineHeight: '20px',
          }}
        >
          <Box>{''}</Box>
          <Box>{`R$ ${saveValue}`}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '24px',
            color: 'text.primary'
          }}
        >
          <Box>{'Economia de'}  
          <Box sx={{ fontWeight: 'bold' }} component="span">{` ${savePct}%`}</Box>
          </Box>

        </Stack>

        <Box
          sx={{
            my: '20px',
            borderBottom: '1px solid #D1D1D1',
          }}
        />
        <Stack
          direction="row"
          spacing="1"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '15px',
              color: 'text.secondary',
              lineHeight: '24px',
            }}
          >
            {t('cc')}
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '24px',
          }}
        >
          <Box>{'IOF (6,38%)'}</Box>
          <Box>{`R$ ${saleData.simulated?.credit_card?.iof}`}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '24px',
          }}
        >
          <Box>{'Custo / Spread Bancário (4,00%)'}</Box>
          <Box>{`R$ ${saleData.simulated?.credit_card?.fees}`}</Box>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '30px',
          }}
        >
          <Box>{'Total'}</Box>
          <Box>{`R$ ${maskCurrency(valueCC)}`}</Box>
        </Stack>
      </Paper>
    </Box>
  );
}

export default Simulation;

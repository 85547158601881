import { useEffect, useRef, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import PHONE_MASKS from './PhoneMasks';
import PhoneCodes from './PhoneCodes';
import { maskSohNumeros } from '../../../utils/masks';
import { CountryInfo, getCountries } from '../../../services/location';

interface InputProps {
  label?: string;
  country?: string;
  onChange?: any;
  sx?: any;
  error?: boolean;
  value: string;
}

function PhoneField({ label, country, onChange, sx, error, value }: InputProps) {
  const [error_phone, setErrorPhone] = useState(false);
  const [_check_phone, setCheckPhone] = useState(false);
  const [hide_input_phone, setHideInputPhone] = useState(true);
  const [open, setOpen] = useState(false);
  const [list_country, setListCountry] = useState<CountryInfo[]>([
    {
      id: 1,
      name: 'BRASIL',
      iso_2: 'BR',
    },
  ]);
  const [countryIso2, setCountryIso2] = useState('BR');
  const [countryId, setCountryId] = useState(1);

  const ref = useRef<HTMLInputElement>(null);

  function validatePhone(_value: string) {
    const lenghtPhone = (PHONE_MASKS[countryIso2].match(/9/g) || []).length;
    const number_phone = maskSohNumeros(_value);
    if (number_phone.length !== lenghtPhone) {
      return false;
    }

    return true;
  }

  function handleBlur() {
    const lenghtPhone = (PHONE_MASKS[countryIso2].match(/9/g) || []).length;
    const number_phone = maskSohNumeros(value);
    if (number_phone) {
      if (number_phone.length !== lenghtPhone) {
        setErrorPhone(true);
        setCheckPhone(false);
      } else {
        setErrorPhone(false);
        setCheckPhone(true);
      }
    } else {
      setErrorPhone(false);
      setCheckPhone(false);
    }
  }

  async function getCountry() {
    const list = await getCountries();
    setListCountry(list);
  }

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    if (error) {
      setErrorPhone(true);
      setCheckPhone(false);
    }
  }, [error]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Autocomplete
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setHideInputPhone(true);
          ref.current?.focus();
        }}
        sx={
          hide_input_phone
            ? { display: 'none' }
            : {
                display: 'block',
                width: '100%',
                maxWidth: { sm: '100%', md: '400px' },
              }
        }
        options={list_country}
        autoHighlight
        defaultValue={list_country[0]}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          if (!value) {
            return;
          }
          setCountryIso2(value.iso_2);
          setCountryId(Number(value.id));
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Box
              component="img"
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.iso_2.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.iso_2.toLowerCase()}.png 2x`}
              alt=""
            />
            {`${option.name} (+${PhoneCodes[option.iso_2]})`}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Numero para contato"
            placeholder="Informe o DDI do país"
            autoComplete="off"
            onMouseEnter={() => setOpen(true)}
          />
        )}
      />
      <InputMask
        inputRef={ref}
        mask={PHONE_MASKS[countryIso2]}
        value={value}
        onChange={(e: any) => {
          setErrorPhone(false);
          onChange({
            countryId: countryId.toString(),
            countryIso2: PhoneCodes[countryIso2],
            phone: maskSohNumeros(e.target.value),
            isValid: validatePhone(e.target.value ),
          });
        }}
      >
        <TextField
          label={label}
          fullWidth
          sx={{
            display: !hide_input_phone ? 'none' : 'block',
          }}
          helperText={error_phone && 'Número inválido'}
          autoComplete="off"
          error={error_phone}
          placeholder={PHONE_MASKS[countryIso2]}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                onClick={() => {
                  setHideInputPhone(false);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '& > img': { mr: 2, flexShrink: 0 },
                  }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${countryIso2.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${countryIso2.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {`+${PhoneCodes[countryIso2]}`}
                  <ArrowDropDownIcon />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </InputMask>
    </Box>
  );
}

export default PhoneField;

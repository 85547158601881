import { Box, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/PrimaryButton';
interface ErrorProps {
  open: boolean
  onClose: () => void
  text?: string
}

function Error( { open, onClose, text }: ErrorProps) {
  const { t } = useTranslation('');

  return (
    <Drawer
      PaperProps={{
        sx: { width: '38.125rem' },
      }}
      anchor="right"
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          m: '40px',
          my: 'auto',
        }}
      >
        <Box
          sx={{
            width: '315px',
            m: 'auto',
          }}
        >
          <Box
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '33px',
              textAlign: 'center',
              color: 'warning.dark',
            }}
          >
            {t('errorTitle')}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              my: '40px',
            }}
          >
            <Box component="img" src="/alert.svg" />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              color: 'text.secondary',
              mb: '60px',
            }}
          >
            {text ? t(text) : t('error')}
          </Box>
          <Box>
            <PrimaryButton
              variant="contained"
              fullWidth
              onClick={() => {
                onClose();
              }}
            >
              {t('backButton')}
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default Error;

import {
  Box,
  StepIconProps,
} from '@mui/material';

function NumberStepIcon({ icon }: StepIconProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        borderColor: 'success.main',
        background: '#3CC13B',
        color: '#ffffff',
        borderRadius: '50px',
        width: '35px',
        height: '35px',
        mr: '15px',
        '@media print': {
          borderColor: '#000000',
          background: '#000000',
          color: '#000000',
        }
      }}
    >
      <Box
        sx={{
          background: 'success.main',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '32px',
          '@media print': {
            borderColor: '#000000',
            background: '#000000',
            color: '#000000',
            lineHeight: '24px',
          }
        }}
      >
        {icon}
      </Box>
    </Box>
  );
}

export default NumberStepIcon;

import { Box, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GoBack from '../../components/GoBack';

interface ErrorProps {
  open: boolean;
  onClose: () => void;
}

function Limit({ open, onClose }: ErrorProps) {
  const { t } = useTranslation('qrcode');

  return (
    <Drawer
      PaperProps={{
        sx: { width: '38.125rem' },
      }}
      anchor="right"
      disableEscapeKeyDown
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          m: '40px',
        }}
      >
        <GoBack to={onClose} />
        <Box
          sx={{
            width: '400px',
            mx: 'auto',
          }}
        >
          <Box
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '33px',
              textAlign: 'center',
              color: 'primary.main',
              my: '40px',
            }}
          >
            {t('limitClient')}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              mb: '60px',
            }}
          >
            {t('appMessage')}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              my: '40px',
            }}
          >
            <Box
              component="img"
              src="/app_qr-code.png"
              sx={{ color: 'warning.dark', width: '200px', height: '200px' }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <Box component="img" src="/app store.png" />
            <Box component="img" src="/google play.png" />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default Limit;

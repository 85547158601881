import { useContext, useEffect, useState } from 'react';
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCepInfo } from '../../services/cep';
import { maskCep, maskSohNumeros } from '../../utils/masks';
import H3 from '../../components/H3';
import PrimaryButton from '../../components/PrimaryButton';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';
import {
  CityInfo,
  getCities,
  getStates,
  StateInfo,
} from '../../services/location';
import { RegisterContext } from '../../contexts/RegisterContext';
import TextFieldOnOff from '../../components/forms/TextFieldOnOff';

type RegisterStepTwoProps = {
  nextToTermsAcceptance: () => void;
};

const RegisterStepTwo = (props: RegisterStepTwoProps) => {
  const { nextToTermsAcceptance } = props;
  const { t } = useTranslation('register');
  const { registerData, setRegisterData } = useContext(RegisterContext);

  const [states, setStates] = useState<StateInfo[]>();
  const [cities, setCities] = useState<CityInfo[]>();

  const [cep, setCep] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const [cityLock, setCityLock] = useState(false);
  const [street, setStreet] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [stateName, setStateName] = useState<string>('');
  const [stateLock, setStateLock] = useState(false);
  const [noNumber, setNoNumber] = useState<boolean>(false);
  const [extraInfo, setExtraInfo] = useState<string>('');
  const [CEPError, setCEPError] = useState<boolean>(false);
  const [stateError, setStateError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [neighborhoodError, setNeighborhoodError] = useState<boolean>(false);
  const [streetError, setStreetError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [noNeighborhood, setNoNeighborhood] = useState<boolean>(false);

  useEffect(() => {
    loadStates();
  }, []);

  useEffect(() => {
    loadCities();
  }, [state]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setState(registerData.stateId?.toString() ?? '');
      setCity(registerData.cityId?.toString() ?? '');
      setStreet(registerData.street);
      setNumber(registerData.number ?? '');
      setNeighborhood(registerData.neighborhood);
      setExtraInfo(registerData.additionalAddress);
      setCep(registerData.zipCode);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [registerData]);

  useEffect(() => {
    if (cep.length === 9) {
      updateCEP();
    }
    setStateLock(false);
    setCityLock(false);
  }, [cep]);

  async function loadStates() {
    try {
      const res = await getStates();
      setStates(res);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCities() {
    if (!state) return;
    try {
      const res = await getCities(state);

      if (cityName) {
        const queryCity = res?.find(
          (obj) => obj.name.toLowerCase() === cityName.toLowerCase(),
        );

        if (queryCity) {
          setCity(queryCity.id.toString());
          setCityLock(true);
        }
      }

      setCities(res);
    } catch (error) {
      console.log(error);
    }
  }

  const updateCEP = async () => {
    try {
      const info = await getCepInfo(maskSohNumeros(cep));

      if (info.erro) {
        setState('');
        setStateName('');
        setCity('');
        setCityName('');
        setNeighborhood('');
        setStreet('');
        return;
      }

      const queryState = states?.find(
        (obj) => obj.initials.toLowerCase() === info.uf.toLowerCase(),
      );

      if (queryState) {
        setState(queryState.id.toString());
        setStateName(queryState.name);
        setStateLock(true);
      }

      const queryCity = cities?.find(
        (obj) => obj.name.toLowerCase() === info.localidade.toLowerCase(),
      );

      if (queryCity) {
        setCity(queryCity.id.toString());
        setCityName(queryCity.name);
        setCityLock(true);
      } else {
        setCityName(info.localidade);
      }

      setNeighborhood(info.bairro);
      setStreet(info.logradouro);

      setCityError(false);
      setStateError(false);
      setStreetError(false);
      setNumberError(false);
      setNeighborhoodError(false);
    } catch (err) {}
  };

  const handleCEPChange = (value: string) => {
    value = maskCep(value);
    setCep(value);
  };

  function validate() {
    let hasError = false;

    if (validateCEP(cep)) {
      hasError = true;
    }

    if (validateState(state)) {
      hasError = true;
    }

    if (validateCity(city)) {
      hasError = true;
    }

    if (validateNeighborhood(neighborhood)) {
      hasError = true;
    }

    if (validateStreet(street)) {
      hasError = true;
    }

    if (validateNumber(number)) {
      hasError = true;
    }

    return hasError;
  }

  const handleSubmit = () => {
    if (validate()) {
      return;
    }

    setRegisterData({
      ...registerData,
      zipCode: cep,
      state: stateName,
      stateId: Number(state),
      city: cityName,
      cityId: Number(city),
      neighborhood,
      street,
      number: number,
      additionalAddress: extraInfo,
    });

    nextToTermsAcceptance();
  };

  const validateCEP = (string: string) => {
    if (string.length < 9) {
      setCEPError(true);
      return true;
    }
    setCEPError(false);
    return false;
  };

  const validateState = (value: string) => {
    if (parseInt(value)) {
      setStateError(false);
      return false;
    }
    setStateError(true);
    return true;
  };

  const validateCity = (value: string) => {
    if (!parseInt(value)) {
      setCityError(true);
      return true;
    }
    setCityError(false);
    return false;
  };

  const validateNeighborhood = (string: string) => {
    if (!noNeighborhood && string.length < 3) {
      setNeighborhoodError(true);
      return true;
    }
    setNeighborhoodError(false);
    return false;
  };

  const validateStreet = (string: string) => {
    if (string.length < 3) {
      setStreetError(true);
      return true;
    }
    setStreetError(false);
    return false;
  };

  const validateNumber = (string: string) => {
    if (!noNumber && string.length < 1) {
      setNumberError(true);
      return true;
    }
    setNumberError(false);
    return false;
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <H3>{t('step2Title')}</H3>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={CEPError}
            fullWidth
            label={t('cep')}
            value={cep}
            inputProps={{ maxLength: 9 }}
            onChange={(e) => {
              handleCEPChange(e.target.value);
              validateCEP(e.target.value);
            }}
            helperText={
              <Box component="small" fontSize="0.75rem" color="error">
                {CEPError && t('invalid')}
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            select
            fullWidth
            label={t('state')}
            value={state}
            onChange={(e) => {
              setState(e.target.value);
              const name = states?.find(
                (obj) => obj.id === parseInt(e.target.value),
              );
              setStateName(name?.name ?? '');
              validateState(e.target.value);
            }}
            error={stateError}
            helperText={
              <Box component="small" fontSize="0.75rem" color="error">
                {stateError && t('invalid')}
              </Box>
            }
            InputProps={{
              readOnly: stateLock,
            }}
          >
            {states &&
              states.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            select
            error={cityError}
            fullWidth
            label={t('city')}
            onChange={(e) => {
              setCity(e.target.value);
              setCityName(
                cities?.find((c) => c.id === e.target.value)?.name ?? '',
              );
              validateCity(e.target.value);
            }}
            value={city}
            helperText={
              <Box component="small" fontSize="0.75rem" color="error">
                {cityError && t('invalid')}
              </Box>
            }
            InputProps={{
              readOnly: cityLock,
            }}
          >
            {cities &&
              cities.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextFieldOnOff
            testId="neighbor"
            label={t('neighbor')}
            labelCheck={t('Sem bairro')}
            value={neighborhood}
            error={neighborhoodError}
            checked={noNeighborhood}
            onChange={(v) => setNeighborhood(v)}
            onCheck={(v) => {
              setNoNeighborhood(v);
            }}
            helperText={t('Bairro inválido')}
            onBlur={() => setNeighborhoodError(false)}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            error={streetError}
            fullWidth
            label={t('street')}
            value={street}
            onChange={(e) => {
              setStreet(e.target.value);
              validateStreet(e.target.value);
            }}
            helperText={
              <Box component="small" fontSize="0.75rem" color="error">
                {streetError && t('invalid')}
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextFieldOnOff
            testId="number"
            label={t('number')}
            labelCheck={t('Sem número')}
            value={number}
            error={numberError}
            checked={noNumber}
            onChange={(v: any) => {
              setNumber(v);
            }}
            onCheck={(v) => {
              setNoNumber(v);
            }}
            helperText={t('Número inválido')}
            onBlur={() => setNumberError(false)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={t('info')}
            value={extraInfo}
            onChange={(e) => setExtraInfo(e.target.value)}
          />
        </Grid>
      </Grid>

      <PrimaryButton
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        sx={{
          mt: '2.875rem',
          alignSelf: 'center',
          maxWidth: '20rem',
        }}
      >
        {t('next')}
      </PrimaryButton>

      <Box mt="3.3125rem">
        <TermsAndPrivacy />
      </Box>
    </Box>
  );
};

export default RegisterStepTwo;

import { Button, styled } from '@mui/material';

const PrimaryButton = styled(Button)(({ theme }) => ({
  height: '3.5rem',
  fontFamily: theme.typography?.fontFamily,
  fontWeight: '700',
  fontSize: '1.125rem',
  textTransform: 'none',
  borderRadius: '6px',
  '@media print': { display: 'none' }
}));

export default PrimaryButton;

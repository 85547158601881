import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TermsProps = {
  durationSecs: number
}

function Terms({ durationSecs }: TermsProps) {
  const { t } = useTranslation('terms');

  return (
    <Box
      sx={{
        '@media print': { px: '6rem' },
      }}>
      <Box
        sx={{
          fontSize: '1rem',
          fontWeight: '600',
          lineHeight: '21px',
          pt: '24px',
          color: 'text.secondary',
          '@media print': { color: '#000000' },
        }}
      >
        {t('attention')}
      </Box>
      <Box
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '21px',
          textJustify: 'inter-word',
          textAlign: 'justify',
          ml: '-20px',
          '@media print': { lineHeight: 1.5, color: '#000000' },
        }}
      >
        <ul>
          <li>{t('att1')}</li>
          <li>{t('att2')}</li>
        </ul>
      </Box>
      <Box
        sx={{
          fontSize: '1rem',
          fontWeight: '600',
          lineHeight: '21px',
          color: 'text.secondary',
          '@media print': { color: '#000000' },
        }}
      >
        {t('title')}
      </Box>
      <Box
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '21px',
          textJustify: 'inter-word',
          textAlign: 'justify',
          ml: '-20px',
          '@media print': { lineHeight: 1.5, color: '#000000' },
        }}
      >
        <ul>
          <li>{`${t('term1', { DurationInMinutes: Math.round(durationSecs / 60) })}`}</li>
          <li>{t('term2')}</li>
          <li>{t('term3')}</li>
          <li>{t('term4')}</li>
          <li>{t('term5')}</li>
          <li>{t('term6')}</li>
          <li>{t('term7')}</li>
        </ul>
      </Box>
    </Box>
  );
}

export default Terms;

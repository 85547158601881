import { createContext, Dispatch, SetStateAction } from 'react';

export type UserAPIData = {
  name: string;
  email: string;
  phone: string;
  level: number;
  cpf_cnpj: string;
  gender: number;
  pinReset: boolean;
  address: {
    address: string;
    city: { name: string; cod: number };
    state: { name: string; cod: number };
    country: { name: string; cod: number };
    complement: string;
    zipcode: string;
    number: string;
  };
  signupStage: number;
  currency: {
    cod: string;
    flag_path: string;
    name: string;
    symbol: string;
  }
};

const UserContext = createContext<
  [Partial<UserAPIData>, Dispatch<SetStateAction<Partial<UserAPIData>>>]
>([{}, () => {}]);

export default UserContext;

import { SxProps, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Cell(props: { label: string; sx?: SxProps }) {
  const { t } = useTranslation('statement');
  const { label, sx } = props;

  return (
    <TableCell
      sx={{
        ...sx,
        color: 'text.primary',
        fontWeight: 'bold',
      }}
    >
      {t(label)}
    </TableCell>
  );
}

function StatementHeader() {
  return (
    <TableHead
      sx={{
        '& th': {
          borderBottomStyle: 'dashed',
        },
        '& th:first-child': {
          borderRadius: '10px 0 0 0',          
        },
        '& th:last-child': {
          borderRadius: '0 10px 0 0',
          borderBottomStyle: 'none',
        },
        // display: {
        //   xs: 'none',
        //   sm: 'none',
        //   md: 'none',
        //   lg: 'table-header-group',
        // },
      }}
    >
      <TableRow>
        <Cell label={'datetime'} />
        <Cell label={'status'} />
        <Cell
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
            },
          }}
          label={'payMethod'}
        />
        <Cell label={'client'} />
        <Cell
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
            },
          }}
          label={'document'}
        />
        <Cell label={'amount'} />
        <Cell
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
            },
          }}
          label={'id'}
        />
        <Cell label={''} />
      </TableRow>
    </TableHead>
  );
}

export default StatementHeader;

import { get, put } from './generic';

export interface PixCreateRequest {
  quotation_id: string;
  cpf: string;
}

export interface PixCreateResponse {
  pix_id: number;
  qrcode_content: string;
  image_url: string;
  expire_at: string;
  transaction: string;
  name: string;
  doc: string;
  ttl: number;
}

export async function createPix(
  data: PixCreateRequest,
): Promise<PixCreateResponse> {
  try {
    const res = await put('/pix/generate', data);

    if (res.data.errors && res.data.errors.length) {
      throw new Error(res.data.errors[0].status);
    }

    return res.data.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

// @TODO endpoint ainda não implementado
// Não usar este serviço ainda
export async function getDataPix(pixUuid: string) {
  try {
    const res = await get(`/pix/$${pixUuid}`);

    if (res.data.errors && res.data.errors.length) {
      throw new Error(res.data.errors[0].status);
    }

    return res.data.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export async function verifyPix(pixId: number | string) {
  try {
    const res = await get(`/pix/consult/${pixId}`);
    return res.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
import { Box, Drawer, Typography } from '@mui/material';
import H2 from '../../components/H2';
import PrimaryButton from '../../components/PrimaryButton';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';

type ConfirmRegisterProps = {
  open: boolean;
  onNext: () => void;
};

const ConfirmRegister = ({ open, onNext }: ConfirmRegisterProps) => {
  return (
    <Drawer anchor="right" open={open} sx={{ zIndex: '9999' }}>
      <Box
        sx={{
          background: 'white',
          minHeight: '100vh',
          px: '1.5625rem',
          py: '2.5rem',
          width: { xs: '100vw', sm: '38.3125rem' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mx: 'auto',
            mt: '2.5rem',
            maxWidth: '22.125rem',
          }}
        >
          <H2 sx={{ color: 'success.dark' }}>
            Cadastro do cliente confirmado!
          </H2>
          <Box
            alt="Sad Emote"
            component="img"
            loading="lazy"
            src="/check_green.svg"
            sx={{
              height: '7.9375rem',
              mt: '3.1875rem',
              width: '6.8125rem',
            }}
          />
          <Typography
            sx={{ mb: '4.375rem', mt: '2.625rem', textAlign: 'center' }}
          >
            Pronto! Uma nova tela aparecerá para o cliente realizar o pagamento
            pelo banco de sua preferência.
          </Typography>
          <PrimaryButton
            fullWidth
            onClick={onNext}
            variant="contained"
            sx={{ maxWidth: '20rem' }}
          >
            Continuar
          </PrimaryButton>
        </Box>
        <Box mx="auto" mt="4.375rem">
          <TermsAndPrivacy />
        </Box>
      </Box>
    </Drawer>
  );
};

export default ConfirmRegister;

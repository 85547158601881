import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

interface Props {
  value: string;
  label: string;
  labelCheck: string;
  error: boolean;
  helperText: string;
  checked: boolean;
  testId?: string;
  onChange: (v: string) => void;
  onCheck: (v: boolean) => void;
  onBlur: () => void;
}

function TextFieldOnOff({
  value,
  label,
  labelCheck,
  error,
  helperText,
  testId,
  onChange,
  onCheck,
  onBlur,
  checked,
}: Props) {
  const [readonly, setReadonly] = useState<boolean>();

  useEffect(() => {
    setReadonly(checked ? true : false);
  }, [checked]);

  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      value={value}
      error={error}
      onBlur={onBlur}
      helperText={error && helperText}
      onChange={(e) => onChange(e.target.value)}
      InputLabelProps={{
        disabled: readonly,
      }}
      inputProps={{
        "data-testid": testId,
      }}
      InputProps={{
        disabled: readonly,
        endAdornment: (
          <FormControlLabel
            label={labelCheck}
            sx={{
              fontWeight: 400,
              fontSize: '10px',
              whiteSpace: 'nowrap',
              color: error ? 'error.main' : 'text.secondary',
            }}
            control={
              <Checkbox
                size="small"
                checked={checked}
                sx={{
                  color: error ? 'error.main' : 'text.secondary',
                }}
                value={readonly}
                onChange={(e) => {
                  onCheck(e.target.checked);
                  onChange('');
                }}
              />
            }
          />
        ),
      }}
    />
  );
}

export default TextFieldOnOff;

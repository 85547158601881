import { Box, List, ListItemButton, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import nav from '../../nav';
import { HOME_PAGE_PATH } from '../../utils/constants';

interface MenuProps {
  divider?: boolean;
  opened?: boolean
  handleMenu: () => void;
}

function Menu({ divider, opened = true, handleMenu }: MenuProps) {
  const { t } = useTranslation('menu');
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: opened ? 'left' : 'center',
        pl: opened ? '20px' : '0px',
        transition: 'alignItems .4s ease-in-out'
      }}
    >
      {nav.map((item, index) => (
        <Tooltip title={t(item.name)} key={index}>
          <ListItemButton
            divider={divider ?? false}
            key={`${item.name}-${index}`}
            sx={{
              display: 'flex',
              flexDirection: opened ? 'row' : 'column',
              flex: opened ? '1 0 41%' : 'none',
              p: '11px',
              m: '2px',
              '&:hover': {
                background: 'none !important',
              },
              color:
                item.path == location.pathname
                  ? 'primary.main'
                  : '#5F5F5F',
              transition: 'all .1s ease-in-out'
            }}
            onClick={() => {
              item.task && item.task();
              if (opened) handleMenu();
              navigate(item.path ?? HOME_PAGE_PATH);
            }}
          >
            {item.icon}
            <Box component="span" sx={{ ml: opened ? '30px' : 'none', display: opened ? 'block' : 'none' }}>{t(item.name)}</Box>
          </ListItemButton>
        </Tooltip>
      ))}
    </List>
  );
}

export default Menu;

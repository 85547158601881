import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { getCurrencySimbol, intlCurrencyMask } from '../../utils/currency';
import { Header } from './header';
import { Label } from './label';
import { Value } from './value';
import { maskCurrency } from '../../utils/masks';
import { useSellerInfo } from '../SellerInfo';
import DefaultButton from '../DefaultButton';
import PrimaryButton from '../PrimaryButton';
import TermsAndPrivacy from '../TermsAndPrivacy';

export type ReceiptDataProps = {
  confirmationDatetime: Date | string | undefined | null
  nameCostumer: string
  currencyAmountBRL: number
  nameSeller: string
  currenyCostumerIso: string
  currencyAmountFNG: number
  quoteBRL: number
  fee: number
  iof: number
  mdr: number
  pixDateTime: Date | string | undefined | null
  documentCPFBRL: string
  pixTransaction?: string
}

type ReceiptProps = {
  data: ReceiptDataProps
  fromSale: boolean
  fromSellerReceipt?: boolean
  onClose: () => void
}

export function Receipt({ data, fromSale = false, onClose, fromSellerReceipt }: ReceiptProps) {
  const { t } = useTranslation('qrcode');
  const { sellerInfo } = useSellerInfo()

  const {
    confirmationDatetime,
    nameCostumer,
    currencyAmountBRL,
    nameSeller,
    currenyCostumerIso,
    currencyAmountFNG,
    mdr,
    quoteBRL,
    fee,
    iof,
    pixDateTime,
    documentCPFBRL } = data

  const amountNetUSD = currencyAmountFNG - mdr

  return (
    <Box
      data-testid="receipt-container"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '375px',
        maxWidth: '593px',
        width: '100%',
        paddingX: '5rem',
        '@media print': {
          transform: fromSale ? 'scale(.80)' : 'scale(1)',
          '@page': {
            margin: 0,
            marginTop: fromSale ? '6mm !important' : '5mm !important',
            size: 'auto',
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '29px',
            textAlign: 'center',
            '@media print': { color: '#000000', order: '2' },
          }}
        >
          {t('Comprovante de transação')}
        </Box>
        <Box
          sx={{
            fontWeight: '700',
            fontSize: '.875rem',
            lineHeight: '1rem',
            textAlign: 'center',
            '@media print': { color: '#000000', order: '2' },
          }}
        >
          {fromSellerReceipt ? t('Via do Estabelecimento') : t('Via do cliente')}
        </Box>
        <Box
          component="img"
          sx={{
            display: 'none',
            '@media print': {
              display: 'block',
              marginBottom: '20px',
              order: '1'
            },
            maxWidth: '500px',
            width: '50%'
          }}
          src="/braza-logo-center-black.svg"
          alt="Braza Checkout"
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '15px'
        }}>
          <Box
            sx={{
              display: 'block',
              textAlign: 'center',
              my: '15px',
              '@media print': { display: 'none' },
            }}
          >
            <Box component="img" src="/check_green.svg" width={51} />
          </Box>
          {fromSale &&
            <Box
              sx={{
                display: 'block',
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '33px',
                color: 'success.dark',
                '@media print': { display: 'none' },
              }}
            >
              {t('successPaymentTitle')}
            </Box>
          }
        </Box>

        <Box
          sx={{
            display: 'block',
            textAlign: 'center',
            fontSize: '16px',
            '@media print': { display: 'none' },
          }}
        >
          {t('success')}
        </Box>

        <Box data-testid="receipt-container-data" sx={{
          width: '100%',
          '@media print': {
            order: '3',
            '@page': {
              width: '58mm'
            }
          },
        }}>
          <Box
            data-testid="receipt-confirmationDate"
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              textAlign: 'center',
              mt: '36px',
              mb: '20px',
              '@media print': { fontWeight: '700', color: '#000000', mt: '2px', },
            }}
          >
            {confirmationDatetime && confirmationDatetime.toLocaleString()}
          </Box>
          <Header>{t('Origem')}</Header>
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('Nome')}:</Label>
            <Value data-testid="receipt-nameCostumer">{nameCostumer}</Value>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              borderBottom: '1px dashed #D6DCE8',
            }}
          >
            <Label>{t('Valor')}:</Label>
            <Value data-testid="receipt-currencyAmountBRL">
              {
                `R$ ${intlCurrencyMask(
                  currencyAmountBRL.toFixed(2),
                  'pt-BR',
                )}`
              }
            </Value>
          </Stack>
          <Header>{t('Destino')}</Header>
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('Nome')}:</Label>
            <Value data-testid="receipt-nameSeller">{nameSeller}</Value>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              borderBottom: '1px dashed #D6DCE8'
            }}
          >
            <Label>{t('Valor')}:</Label>
            <Value data-testid="receipt-currencyAmountFNG">
              {`${getCurrencySimbol(
                sellerInfo.currencies,
                currenyCostumerIso,
              )} ${maskCurrency(
                currencyAmountFNG.toFixed(2),
              )}`
              }
            </Value>
          </Stack>
          <Header>{t('Resumo da operação')}</Header>
          {
            fromSellerReceipt ?
              (
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Label>{t('Valor líquido')}:</Label>
                    <Value data-testid="receipt-amountNetUSD">
                      {`${getCurrencySimbol(
                        sellerInfo.currencies,
                        currenyCostumerIso,
                      )} ${maskCurrency(
                        amountNetUSD.toFixed(2),
                      )}`
                      }
                    </Value>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      mb: '1rem'
                    }}
                  >
                    <Label>{t('MDR')}:</Label>
                    <Value data-testid="receipt-mdrPartner">
                      {`${getCurrencySimbol(
                        sellerInfo.currencies,
                        currenyCostumerIso,
                      )} ${maskCurrency(
                        mdr.toFixed(2),
                      )}`
                      }
                    </Value>
                  </Stack>
                </>
              ) : (<></>)
          }
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('Câmbio')}:</Label>
            <Value data-testid="receipt-quoteBRL">
              {`R$ ${intlCurrencyMask(
                quoteBRL.toFixed(2),
                'pt-BR',
              )}`}
            </Value>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('Spread bancário')}:</Label>
            <Value data-testid="receipt-fee">
              {`R$ ${intlCurrencyMask(
                fee.toFixed(2),
                'pt-BR',
              )}`}
            </Value>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              borderBottom: '1px dashed #D6DCE8',
            }}
          >
            <Label>{t('IOF (0,38%)')}:</Label>
            <Value data-testid="receipt-iof">
              {`R$ ${intlCurrencyMask(iof.toFixed(2), 'pt-BR',)}`}
            </Value>
          </Stack>
          <Header>{t('Transação')}</Header>
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('Número da transação')}:</Label>
            <Value data-testid="receipt-pixTransaction">{data?.pixTransaction}</Value>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('CPF')}:</Label>
            <Value data-testid="receipt-documentCPFBRL">{documentCPFBRL}</Value>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Label>{t('Geração')}:</Label>
            <Value data-testid="receipt-pixDateTime">{pixDateTime && pixDateTime?.toLocaleString()}</Value>
          </Stack>
          <Stack direction="row" justifyContent="space-between"
            sx={{
              visibility: 'hidden',
              '@media print': {
                visibility: 'visible !important',
                borderBottom: '3px dashed #000000',
                paddingTop: '1.5rem'
              }
            }}></Stack>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media print': { display: 'none' }
          }}>
            <Box
              sx={{
                minWidth: '280px',
                maxWidth: '450px',
                width: '100%',
                paddingY: '15px',
                '@media print': { display: 'none' }
              }}
            >
              {!fromSale ? (
                <DefaultButton
                  data-testid="receipt-button-close-sale"
                  variant="contained"
                  fullWidth
                  color="error"
                  onClick={onClose}
                  sx={{
                    background: '#B23028',
                    color: '#ffffff',
                    border: 'none',
                    '@media print': { display: 'none' }
                  }}>
                  FECHAR
                </DefaultButton>
              ) : (
                <PrimaryButton
                  data-testid="receipt-button-close-reprint"
                  variant="contained"
                  fullWidth
                  onClick={onClose}
                >
                  Concluir
                </PrimaryButton>
              )}
            </Box>
          </Box>
          <TermsAndPrivacy />
        </Box>
      </Box>
    </Box>
  );
}

export default Receipt;

import { get } from './generic';

export interface CountryInfo {
  id: number;
  name: string;
  iso_2: string;
}

export interface StateInfo {
  id: number;
  name: string;
  initials: string;
}

export interface CityInfo {
  id: string;
  stateId: string;
  name: string;
}

export interface DocInfo {
  cod_documento_cadastro: number;
  cod_pais: number;
  cod_tipo_documento: number;
  nome: string;
  flag_frente_verso: boolean;
  instrucao_pt: string;
  instrucao_en: string;
}

export interface countryResponse {
  id: number;
  name: string;
  code: string;
}

export const DEFAULT_COUNTRY_BR: CountryInfo = {
  id: 1,
  name: 'Brasil',
  iso_2: 'BR',
};

export async function getCountries(): Promise<CountryInfo[]> {
  try {
    const {
      data: {
        data: { country: countryResponse },
      },
    } = await get(`/country/countries`);

    const countries = countryResponse.map(
      ({ id, name, code: iso_2 }: countryResponse) => {
        return {
          id,
          name,
          iso_2,
        };
      },
    );

    return countries;
  } catch (error) {
    return [];
  }
}

export async function getStates(): Promise<StateInfo[]> {
  try {
    const res = await get(`/state/states`);
    if (res.status === 204) {
      return [];
    }
    return res.data.data.state;
  } catch (error) {
    return [];
  }
}

export async function getCities(state: string): Promise<CityInfo[]> {
  try {
    const res = await get(`/city/${state}`);
    if (res.status === 204) {
      return [];
    }
    return res.data.data.city;
  } catch (error) {
    return [];
  }
}

export async function getDocuments(country: string): Promise<DocInfo[]> {
  try {
    const res = await get(`/documents/${country}`);
    if (res.status === 204) {
      return [];
    }
    return res.data;
  } catch (error) {
    return [];
  }
}

import { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
  Drawer,
  Stack,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Currency } from '../../services/currency';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import TransactionNumber from '../../utils/transactionNumber';
import { SaleContext } from '../../contexts/SaleContext';
import { alphaNumberOnly, maskSohNumeros } from '../../utils/masks';
import { useTranslation } from 'react-i18next';
import GoBack from '../../components/GoBack';
import {
  QuoteCreateRequest,
  QuoteSimulateResponse,
  simulateQuote,
  TransactionLimitError,
} from '../../services/quote';
import Limit from './Limit';
import { useSellerInfo } from '../../components/SellerInfo';
import { intlCurrencyMask } from '../../utils/currency';
import { useSnack } from '../../components/SnackProvider';
import Simulation from '../../components/Simulation';
import Instructions from '../../components/Instructions';
import { VERIFIY_CPF_PAGE_PATH } from '../../utils/constants';

export default function SaleForm() {
  const { t } = useTranslation('sale');
  const navigate = useNavigate();
  const snack = useSnack();
  const { sellerInfo } = useSellerInfo();
  const [limitMessageOpen, setLimitMessageOpen] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [transactionError, setTransactionError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<Currency[]>();
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>();
  const [amountValue, setAmountValue] = useState('0');
  const [transactionStr, setTransactionStr] = useState('');
  const { saleData, setSaleData } = useContext(SaleContext);

  const [hasMdr, setHasMdr] = useState<boolean>(false);

  const [simulation, setSimulation] = useState(false);
  const [simulateId, setSimulateId] = useState('');
  const simulateBox = useRef<HTMLDivElement>(null);

  const GetTextSimulation = () => {
    if (!simulation) {
      return (
        <>{t('simulate')}</>
      )
    }

    return (
      <>{t('next')}</>
    )
  }

  useEffect(() => {
    document.title = t('title');
    setTransactionStr(TransactionNumber);
  }, []);

  useEffect(() => {
    if (simulation && simulateBox.current) {
      simulateBox.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [simulation]);

  useEffect(() => {
    const semBr = sellerInfo.currencies.filter((obj) => obj.iso !== 'BRL');

    if (!semBr || !semBr.length) {
      return;
    }

    setCurrencies(semBr);

    const dolar = semBr.find((obj) => obj.iso === 'USD');
    if (dolar) {
      setSelectedCurrency(dolar);
    } else {
      setSelectedCurrency(semBr[0]);
    }
  }, [sellerInfo]);

  useEffect(() => {
    const countryLocale = selectedCurrency?.country
      ? selectedCurrency?.country
      : 'en-US';
    setAmountValue(intlCurrencyMask(amountValue, countryLocale));
  }, [selectedCurrency]);

  useEffect(() => {
    setSimulation(false);
    setHasMdr(false);
  }, [selectedCurrency, amountValue]);

  const handleCurrencySelectChange = (value: string) => {
    setSelectedCurrency(currencies?.find((obj) => obj.iso === value));
  };

  const validateAmountField = (amount: string) => {
    if (!amount || !parseInt(maskSohNumeros(amount))) {
      setAmountError(true);
      return false;
    }
    setAmountError(false);
    return true;
  };

  const validateTransationNumber = (transation: string) => {
    if (transation !== alphaNumberOnly(transation)) {
      setTransactionError(true)
      return false;
    }

    setTransactionError(false)
    return true
  }

  const handleTransationId = (value: string) => {
    setTransactionStr(value)
    validateTransationNumber(value);
  };

  const countryLocaleCurrency = selectedCurrency?.country
    ? selectedCurrency?.country
    : 'en-US';

  const getMdrTextFieldValue = () => {
    if (saleData.simulated) {
      if (saleData.simulated.mdr) {
        return intlCurrencyMask(
          saleData.simulated?.mdr.toFixed(2),
          countryLocaleCurrency,
        )
      }
    }

    return '0.00'
  }

  const getTotalPaymentTextFieldValue = () => {
    if (saleData.simulated) {
      if (saleData.simulated.preview_receipt.FGNquantity) {
        return intlCurrencyMask(
          saleData.simulated?.preview_receipt.FGNquantity.toFixed(
            2,
          ),
          countryLocaleCurrency,
        )
      }
    }

    return ''
  }

  const handleValueChange = (value: string) => {
    if (maskSohNumeros(value).length >= 8) {
      return;
    }

    setAmountValue(intlCurrencyMask(value, countryLocaleCurrency));
    validateAmountField(value);
  };

  const getSymbolCurrency = selectedCurrency?.symbol || '$'

  const spacingMdr = hasMdr ? 5 : 7

  async function simulate() {
    if (!selectedCurrency) {
      return;
    }

    const amountNumber = parseFloat(maskSohNumeros(amountValue)) / 100;

    const toQuote: QuoteCreateRequest = {
      amount: amountNumber,
      currency_id: selectedCurrency.id,
      transaction: transactionStr,
    };

    const getMdrValueBy = (simulated: QuoteSimulateResponse) => simulated.mdr ? simulated.mdr > 0 : false

    setLoading(true);

    try {
      const simulated = await simulateQuote(toQuote);
      setSaleData({
        ...saleData,
        simulated,
      });
      setHasMdr(getMdrValueBy(simulated));
      setSimulateId(simulated.id);
      setSimulation(true);
    } catch (error) {
      if (error instanceof TransactionLimitError) {
        setLimitMessageOpen(true);
      } else {
        snack.error(t('failTryAgain'));
      }
    }

    setLoading(false);
  }

  const handleSubmit = async () => {
    if (!selectedCurrency) {
      return;
    }

    if (!validateAmountField(amountValue)) {
      return;
    }

    if (!simulation) {
      simulate();
      return;
    }

    const amountNumber = parseFloat(maskSohNumeros(amountValue)) / 100;

    setSaleData({
      ...saleData,
      quote: { id: simulateId, activated: true },
      amount: amountNumber,
      currency: selectedCurrency.iso,
      idTransfer: transactionStr,
      document: '',
    });

    setSimulation(false);
    navigate(VERIFIY_CPF_PAGE_PATH);
  };

  return (
    <Grid
      container
      sx={{
        py: {
          xs: '1.875rem',
        },
        px: {
          xs: 2,
          sm: 10,
          lg: 20,
        },
        mt: {
          xs: 2,
          lg: 1,
        },
      }}
    >
      <Grid item xs={12} sm={9} md={6} mt="2rem">
        <GoBack to={() => navigate('/carteira', { replace: true })} />
        <Typography fontWeight={700} fontSize="1.125rem" color="text.primary">
          {t('sellInfo')}
        </Typography>
        <Box
          autoComplete="off"
          component="form"
          noValidate
          sx={{
            mt: '3.5rem',
            maxWidth: { md: '320px' },
          }}
        >
          <Stack spacing={spacingMdr}>
            <TextField
              select
              label={t('currency')}
              value={selectedCurrency?.iso || 'USD'}
              InputProps={{
                readOnly: (currencies && currencies.length <= 1) || loading,
              }}
              onChange={(e) => handleCurrencySelectChange(e.target.value)}
            >
              {currencies ? (
                currencies.map((curr) => (
                  <MenuItem value={curr.iso} key={curr.iso}>
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_PUBLIC_FLAG_HOST}/${curr.img_country}`}
                      sx={{
                        width: '24px',
                        height: '24px',
                        verticalAlign: 'middle',
                      }}
                    />
                    <Box component="span" fontSize="1rem" ml={2}>
                      {curr.name}&nbsp;({curr.iso})
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">{t('select')}</MenuItem>
              )}
            </TextField>
            <TextField
              error={amountError}
              value={amountValue}
              label={t('amount')}
              onChange={(e) => handleValueChange(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter' && handleSubmit();
              }}
              InputProps={{
                readOnly: loading,
                startAdornment: (
                  <InputAdornment position="start">
                    {selectedCurrency?.symbol ? selectedCurrency?.symbol : '$'}
                  </InputAdornment>
                ),
              }}
              helperText={
                <Box component="small" fontSize="0.75rem" color="error">
                  {amountError && t('invalidAmount')}
                </Box>
              }
            />
            {hasMdr ? (
              <>
                <TextField
                  variant="standard"
                  label={t('MDR')}
                  title={t('Taxa cobrada pela transação')}
                  value={getMdrTextFieldValue()}
                  InputProps={{
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {getSymbolCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="standard"
                  label={t('Valor total')}
                  title={t('Valor total a ser pago')}
                  value={getTotalPaymentTextFieldValue()}
                  InputProps={{
                    readOnly: true,
                    sx: { fontWeight: '700' },
                    startAdornment: (
                      <InputAdornment position="start">
                        {getSymbolCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            ) : (
              ''
            )}
            <TextField
              error={transactionError}
              value={transactionStr}
              label={t('transactionId')}
              inputProps={{
                maxLength: 24,
              }}
              InputProps={{
                readOnly: loading,
                endAdornment: (
                  <IconButton
                    onClick={() => setTransactionStr('')}
                    sx={{ p: '10px' }}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              helperText={
                <Box component="small" fontSize="0.75rem" color="error">
                  {transactionError && t('invalidTransactionId')}
                </Box>
              }
              onChange={(e) => handleTransationId(e.target.value)}
            />
          </Stack>
          <Box
            ref={simulateBox}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {simulation ? <Simulation /> : <></>}
          </Box>
          <PrimaryButton
            variant="contained"
            fullWidth
            disabled={transactionError || amountError || loading}
            sx={{
              my: '2.5rem',
            }}
            onClick={handleSubmit}
          >
            {!loading ? (
              <GetTextSimulation />
            ) : (
              <CircularProgress sx={{ color: 'white' }} />
            )}
          </PrimaryButton>
        </Box>
        <Limit
          open={limitMessageOpen}
          onClose={() => setLimitMessageOpen(false)}
        />
        <Drawer
          variant="persistent"
          open={!simulation}
          anchor="right"
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: { sm: '450px' },
              p: '30px',
              display: { xs: 'none', md: 'block' },
              background: '#ffffff',
              mt: 8
            },
          }}
        >
          <Instructions namespace={'instructionsSale'} />
        </Drawer>
        <Drawer
          variant="persistent"
          open={simulation}
          anchor="right"
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: { sm: '450px' },
              display: { xs: 'none', md: 'block' },
              background: '#ffffff',
              mt: 7
            },
          }}
        >
          <Simulation />
        </Drawer>
      </Grid>
    </Grid>
  );
}

import { Box } from "@mui/material";
import { HTMLProps, ReactNode } from "react";

type ValueReceiptProps = {
  children: ReactNode
  rest?: HTMLProps<HTMLSpanElement>
}

export function Value({ children,...rest }: ValueReceiptProps) {
  return (
    <Box
      {...rest}
      component="span"
      sx={{
        fontWeight: '400',
        fontSize: '14px',
        textAlign: 'right',
        color: 'text.primary',
        '@media print': { color: '#000' },
        lineHeight: '25px',
      }}
    >
      {children}
    </Box>
  );
}
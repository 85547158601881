import { Box, SxProps } from '@mui/system'

interface Props {
    selected: boolean
    click(): void
    text: string
    sx?: SxProps
}

const FilterBox = ({ selected, click, text, sx }: Props) => {
    return (
        <Box sx={{
            color: selected ? 'primary.contrastText' : 'text.primary',
            backgroundColor: selected ? 'text.primary' : 'primary.contrastText',
            px: '9px',
            py: '3px',
            borderRadius: '12px',
            border: selected ? 'hidden' : '1px solid',
            borderColor: 'text.primary',
            cursor: 'pointer',
            ...sx
        }}
            onClick={click}
        >
            {text}
        </Box>
    )
}

export default FilterBox
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { PixCreateResponse } from '../services/pix';
import { QuoteActivateResponse, QuoteSimulateResponse } from '../services/quote';

export interface SaleContextDataType {
  currency: string;
  idOrder: string;
  idTransfer: string;
  document: string;
  amount: number;
  quote?: QuoteActivateResponse;
  simulated?: QuoteSimulateResponse;
  pix?: PixCreateResponse;
  pixDatetime?: string;
  confirmationDatetime?: string;
}

interface SaleContextType {
  saleData: SaleContextDataType;
  setSaleData: Dispatch<SetStateAction<SaleContextDataType>>;
}

const defaultValues: SaleContextDataType = {
  currency: '',
  idOrder: '',
  idTransfer: '',
  document: '',
  amount: 0,
};

export const SaleContext = React.createContext<SaleContextType>({
  saleData: defaultValues,
  setSaleData: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const SaleProvider = (props: Props): ReactElement => {
  const [saleData, setSaleData] = useState<SaleContextDataType>(defaultValues);

  return (
    <SaleContext.Provider value={{ saleData, setSaleData }}>
      {props.children}
    </SaleContext.Provider>
  );
};

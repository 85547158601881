import { styled, TextField } from "@mui/material";

const BlueTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    color: theme.palette.primary.dark,
  },
  '& .MuiInput-root:before': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiInput-root:hover:not(.Mui-disabled):before': {
    borderBottomColor: theme.palette.primary.main,
  },
}));

export default BlueTextField

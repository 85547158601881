import { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import Receipt from '../../components/Receipt';
import { SaleContext } from '../../contexts/SaleContext';
import { useSellerInfo } from '../../components/SellerInfo';
import { ModalReceipt } from '../../components/Receipt/modal';
import { hideCpf } from '../../utils/masks';
import { useNavigate } from 'react-router-dom';

interface SuccessProps {
  open: boolean;
  onClose: () => void;
}

export const SuccessDialog = ({ open, onClose }: SuccessProps) => {
  const navigate = useNavigate();
  const { saleData } = useContext(SaleContext);
  const { sellerInfo } = useSellerInfo();

  useEffect(() => {
    document.title = 'Comprovante - Braza Checkout';
  }, []);

  const dataTransactionSuccess = {
    confirmationDatetime: saleData.confirmationDatetime,
    nameCostumer: saleData.pix?.name || '',
    currencyAmountBRL: saleData.simulated?.preview_receipt.BRLquantity || 0,
    nameSeller: sellerInfo.name,
    currenyCostumerIso: saleData.currency,
    currencyAmountFNG: saleData.simulated?.preview_receipt.FGNquantity || 0,
    quoteBRL: Number(saleData.simulated?.quote),
    mdr: Number(saleData.simulated?.mdr),
    fee: Number(saleData.simulated?.preview_receipt.fees),
    iof: Number(saleData.simulated?.preview_receipt.iof),
    pixDateTime: saleData.pixDatetime,
    documentCPFBRL: hideCpf(saleData.document),
    pixTransaction: saleData.pix?.transaction
  }

  return (
    <ModalReceipt open={open} onClose={() => {
      onClose();
      navigate('/carteira');
    }} id={`opened-modal-success-transaction-${dataTransactionSuccess.pixTransaction}`}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Receipt
          data={dataTransactionSuccess}
          fromSale={true}
          fromSellerReceipt={false}
          key={`success-transaction-${dataTransactionSuccess.pixTransaction}-date${dataTransactionSuccess.confirmationDatetime}`} onClose={() => {
            onClose();
            navigate('/carteira');
          }} />
      </Box>
    </ModalReceipt>
  );
}
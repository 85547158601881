import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';

export interface RegisterContextDataType {
  additionalAddress: string;
  cellPhone: string;
  cellphoneCountryId: number;
  city: string;
  cityId?: number;
  countryCode: string;
  email: string;
  name: string;
  document: string;
  neighborhood: string;
  number?: string;
  state: string;
  stateId?: number;
  street: string;
  zipCode: string;
}

interface RegisterContextType {
  registerData: RegisterContextDataType;
  setRegisterData: Dispatch<SetStateAction<RegisterContextDataType>>;
}

export const DefaultRegisterValues: RegisterContextDataType = {
  additionalAddress: '',
  cellPhone: '',
  cellphoneCountryId: 1,
  city: '',
  cityId: undefined,
  countryCode: '',
  email: '',
  name: '',
  document: '',
  neighborhood: '',
  state: '',
  stateId: undefined,
  street: '',
  number: '',
  zipCode: '',
};

export const RegisterContext = React.createContext<RegisterContextType>({
  registerData: DefaultRegisterValues,
  setRegisterData: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const RegisterProvider = (props: Props): ReactElement => {
  const [registerData, setRegisterData] =
    useState<RegisterContextDataType>(DefaultRegisterValues);

  return (
    <RegisterContext.Provider value={{ registerData, setRegisterData }}>
      {props.children}
    </RegisterContext.Provider>
  );
};

export default {
  translations: {
    terms: 'Terms of usage',
    privacy: 'Privacy',
    tryAgain: 'Try Again',
  },
  dashboard: {
    sellNow: 'Sell now',
  },
  statement: {
    title: 'Transactions',
    search: 'Search',
    datetime: 'DATE/TIME',
    status: 'STATUS',
    payMethod: 'PAYMENT',
    client: 'CUSTOMER',
    document: 'CPF',
    amount: 'AMOUNT',
    id: 'IDENTIFICATION',
  },
  sale: {
    cancelGoToHome: 'Cancelar e voltar para tela inicial',
    invalidTransactionId: 'Only numbers and letters',
  },
  qrcode: {
    step1:
      'First, ask the customer to access the app of their choice and go to the Pix area.',
    step2:
      'Ask to scan the QR Code on the side, in the Pix Copy and Paste area.',
    step3:
      'Ready! Once we receive it, a new payment confirmation screen will open.',
    success:
      'Informe ao cliente que o comprovante desta transação foi enviado para o seu e-mail.',
    error: 'Houve um problema na transação. Tente novamente.',
    expired:
      'Unfortunately, we can only hold the quote for 15 minutes and the deadline has expired.',
    expired2: 'Perform the transaction again.',
    titleDetailsSale: 'Details of payment',
    seeDetails: 'See Details',
    cancelOperation: 'Cancel operation'
  },
  GoBack: {
    'Tela anterior': 'Previous Screen',
  },
  BrazilianResident: {
    'Possui residencia no Brasil': 'Do you have residency in Brazil?',
    'De acordo com o Banco Central do Brasil':
      'According to the Central Bank of Brazil (BCB), it will only be possible to carry out foreign exchange operations with a valid CPF and address in Brazil.',
    'Residente no Brasil': 'Resident in Brazil',
    'Nao residente no Brasil': 'Not residing in Brazil',
    'Infelizmente nao e possivel continuar':
      'Unfortunately, it is not possible to proceed with the transaction due to lack of address in Brazil.',
    'Cancelar e voltar': 'Cancel and Return',
  },
};

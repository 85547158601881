import { get } from './generic';

export async function getSellerData() {
  try {
    const { data: dataResponse } = await get('/seller/info');

    if (dataResponse.errors && dataResponse.errors.length) {
      throw new Error(dataResponse.errors[0].error);
    }

    return dataResponse.data.seller_data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

import {
  Box,
  TextField
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import H3 from '../../components/H3';
import PrimaryButton from '../../components/PrimaryButton';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';
import PhoneField from '../../components/forms/PhoneField';
import { RegisterContext } from '../../contexts/RegisterContext';
import {
  CountryInfo,
  DEFAULT_COUNTRY_BR,
  getCountries,
} from '../../services/location';
import { checkEmail, checkName } from '../../utils/validations';

type RegisterStepOneProps = {
  nextRegisterStep: () => void;
};

const RegisterStepOne = (props: RegisterStepOneProps) => {
  const { nextRegisterStep } = props;
  const { t } = useTranslation('register');
  const { registerData, setRegisterData } = useContext(RegisterContext);
  const [_countries, setCountries] = useState<CountryInfo[]>([
    DEFAULT_COUNTRY_BR,
  ]);
  const [cellPhone, setCellPhone] = useState<string>('');
  const [_cellPhoneCountryId, setCellPhoneCountryId] = useState<number>(1);
  const [countryCode, setCountryCode] = useState<string>('+55');
  const [mail, setMail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [cellPhoneError, setCellPhoneError] = useState<boolean>(false);
  const [mailError, setMailError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    setName(registerData.name);
    setMail(registerData.email);
    setCountryCode(registerData.countryCode);
    setCellPhone(registerData.cellPhone);
  }, [registerData]);

  async function fetchCountries() {
    try {
      const res = await getCountries();
      setCountries(res);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCellPhoneChange = (value: any) => {
    setCellPhone(value.phone);
    setCellPhoneCountryId(value.countryId);
    setCellPhoneError(!value.isValid);
  };

  const validateMail = () => {
    if (mail.length < 3) {
      setMailError(true);
      return true;
    }
    const { error } = checkEmail(mail);
    setMailError(error);
    return error;
  };

  const validateName = () => {
    if (name.length < 3) {
      setNameError(true);
      return true;
    }
    const { error } = checkName(name);
    setNameError(error);
    return error;
  };

  function validate() {
    let hasError = false;

    if (validateName()) {
      hasError = true;
    }

    if (validateMail()) {
      hasError = true;
    }

    if (cellPhoneError || !cellPhone || !cellPhone.length) {
      setCellPhoneError(true);
      hasError = true;
    }

    return hasError;
  }

  const handleSubmit = () => {
    if (validate()) {
      return;
    }

    setRegisterData({
      ...registerData,
      name: name.trim(),
      email: mail.trim(),
      countryCode: countryCode,
      cellPhone: cellPhone,
    });
    nextRegisterStep();
  };

  return (
    <>
      <H3>{t('step1Title')}</H3>
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '600px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '19.8125rem',
            justifyContent: 'space-between',
            mt: '1.375rem',
          }}
        >
          <TextField
            fullWidth
            InputProps={{ readOnly: true }}
            label="CPF"
            value={registerData.document}
          />
          <TextField
            autoComplete="off"
            fullWidth
            error={nameError}
            label={t('name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={() => validateName()}
            helperText={
              <Box component="small" fontSize="0.75rem" color="error">
                {nameError && t('invalid')}
              </Box>
            }
          />
          <TextField
            autoComplete="off"
            fullWidth
            error={mailError}
            label="E-mail"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            onBlur={() => validateMail()}
            helperText={
              <Box component="small" fontSize="0.75rem" color="error">
                {mailError && t('invalid')}
              </Box>
            }
          />
          <PhoneField
            label="Número de celular"
            onChange={handleCellPhoneChange}
            value={cellPhone}
            error={cellPhoneError}
          />
        </Box>
        <PrimaryButton
          disabled={cellPhoneError || mailError || nameError}
          fullWidth
          onClick={handleSubmit}
          sx={{
            alignSelf: 'center',
            maxWidth: '20rem',
            mt: '1.875rem',
          }}
          variant="contained"
        >
          {t('next')}
        </PrimaryButton>
        <Box mt="3.3125rem">
          <TermsAndPrivacy />
        </Box>
      </Box>
    </>
  );
};

export default RegisterStepOne;

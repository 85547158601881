import { Box, Grid, Typography, alpha, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GoBack from '../../components/GoBack';
import { useLoading } from '../../components/Loading';
import { useSnack } from '../../components/SnackProvider';
import {
  DefaultRegisterValues,
  RegisterContext,
  RegisterContextDataType,
} from '../../contexts/RegisterContext';
import { SaleContext } from '../../contexts/SaleContext';
import { doRegister } from '../../services/user';
import ConfirmRegister from './ConfirmRegister';
import RegisterStepOne from './RegisterStepOne';
import RegisterStepTwo from './RegisterStepTwo';
import TermsAcceptance from './TermsAcceptance';

const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('register');
  const loading = useLoading();
  const snack = useSnack();
  const theme = useTheme();

  const { saleData } = useContext(SaleContext);

  const [registerData, setRegisterData] = useState<RegisterContextDataType>(
    DefaultRegisterValues,
  );

  const [registerStep, setRegisterStep] = useState<number>(1);
  const [termsAcceptanceOpen, setTermsAcceptanceOpen] =
    useState<boolean>(false);
  const [confirmRegisterOpen, setConfirmRegisterOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setRegisterData({ ...DefaultRegisterValues, document: saleData.document });
  }, [saleData]);

  const handleGoBack = () => {
    registerStep === 1 ? navigate("/vender/verificar/cpf") : setRegisterStep(1);
  };

  async function handleDone() {
    const data = {
      name: registerData.name,
      birthDate: '0000-00-00',
      email: registerData.email,
      document: registerData.document,
      cellphone: registerData.cellPhone,
      cellphoneCountryId: registerData.cellphoneCountryId,
      address: {
        zipCode: registerData.zipCode,
        address: registerData.street,
        number: registerData.number,
        district: registerData.neighborhood,
        stateId: registerData.stateId,
        city: registerData.city,
      },
    };

    loading.show();

    try {
      await doRegister(data);
      loading.hide();
      setConfirmRegisterOpen(true);
    } catch (error: any) {
      /**
       * Author: Angelo Reis <angelo.reis@3rd.braza.com.br>
       * Data da alteração: 11.04.2023
       * Alteração do tratamento das resposta oriundas do bff
       * referente a task PAGA-692
       */
      let message = ''
      if (error.message) {
        if (error.message.toUpperCase() === 'CLIENT INCONSISTENT') {
          message = 'Cliente incosistente'
        }

        if (error.message.toUpperCase() === 'MULTIPLE CLIENTS ASSIGNED') {
          message = 'Multiplos clientes atribuídos'
        }

        if (error.message.toUpperCase() === 'CPF ALREADY USED') {
          message = 'CPF em uso'
        }

        if (error.message.toUpperCase() === 'E-MAIL ALREADY USED') {
          message = 'E-mail em uso'
        }

        if (error.message.toUpperCase() === 'CLIENT IS PARTNER') {
          message = 'Cliente é um parceiro'
        }

        snack.error(message)
        return
      }

      snack.error(
        'Não foi possível completar a ação. <br />Confira seu email e CPF e tente novamente ou entre em contato com nosso suporte',
      );
      return
    }
  }

  return (
    <RegisterContext.Provider value={{ registerData, setRegisterData }}>
      <Box>
        <Grid container sx={{ p: '1.875rem' }}>
          <Grid item xs={12}>
            <GoBack to={handleGoBack} />
            <Typography
              sx={{
                color: alpha(theme.palette.primary.dark, 0.4),
                fontSize: '14px',
                fontWeight: 600,
                opacity: '40%',
                position: 'absolute',
                right: { xs: '2rem', md: '6.5rem' },
                top: '3.875rem',
              }}
            >
              {`${t('step')} ${registerStep} ${t('of')} 2`}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              ml: { xs: '0', sm: '3rem', md: '17.8125rem' },
              mt: '2.875rem',
            }}
            xs={12}
          >
            {registerStep === 1 && (
              <RegisterStepOne nextRegisterStep={() => setRegisterStep(2)} />
            )}
            {registerStep === 2 && (
              <RegisterStepTwo
                nextToTermsAcceptance={() => setTermsAcceptanceOpen(true)}
              />
            )}
          </Grid>
        </Grid>
        <TermsAcceptance
          open={termsAcceptanceOpen}
          onClose={() => setTermsAcceptanceOpen(false)}
          onNext={() => {
            setTermsAcceptanceOpen(false);
            handleDone();
          }}
        />
        <ConfirmRegister
          open={confirmRegisterOpen}
          onNext={() => {
            setConfirmRegisterOpen(false);
            navigate('/pagamento/pix/qrcode')
          }}
        />
      </Box>
    </RegisterContext.Provider>
  );
};

export default Register;

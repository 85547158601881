import { useState } from "react"

/**
 * @author Angelo Reis <angelo.reis@3rd.braza.com.br>
 * @desciption Adicionado uso do hooks criados para controlar o limite diário
 * e anual.
 * 
 * @DataModificacao 23.05.2023
 * @task PAGA-746 - Ajustes de Navegação
 */
   
export const useLimit = (hasLimit: boolean = true) => {
  const [limitDaily, setLimitDaily] = useState(hasLimit)
  const [limitYear, setLimitYear] = useState(hasLimit)

  const handleLimitDairy = (hasLimitDiary: boolean) => {
    setLimitDaily(hasLimitDiary)
  }

  const handleLimitYear = (hasLimitYear: boolean) => {
    setLimitYear(hasLimitYear)
  }


  return {
    handleLimitDairy,
    handleLimitYear,
    limitDaily,
    limitYear
  }
}
import Statement from '../../components/Statement';

const Dashboard = () => {

  return (
    <>
      <Statement />
    </>
  );
};

export default Dashboard;

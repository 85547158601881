import { Box } from "@mui/material";
import { ReactNode } from "react";

type HeaderReceiptProps = {
  children: ReactNode
}

export function Header({ children }: HeaderReceiptProps) {
  return (
    <Box
      sx={{
        fontWeight: '700',
        fontSize: '14px',
        textAlign: 'left',
        color: 'text.primary',
        '@media print': { color: '#000' },
        lineHeight: '29px',
      }}
    >
      {children}
    </Box>
  );
}
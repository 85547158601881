import { ReactNode, useEffect } from "react"
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

interface ModalReceiptProps {
  open: boolean
  onClose: () => void
  children: ReactNode
  id?: string
  fromSale?: boolean
}

export const ModalReceipt = ({ open, onClose, children, id }: ModalReceiptProps) => {
  const onPrint = () => window.print()

  useEffect(() => {
    if (open) {
      document.title = 'Comprovante - Braza Checkout'
    } else {
      document.title = 'Braza Checkout'
    }
  }, [open]);

  return (
    <Dialog fullScreen disableEscapeKeyDown open={open} onClose={onClose} id={id || ''}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: '.875rem',
        paddingX: { xs: '1rem', sm: '8rem', md: '15rem' },
        '@media print': { display: 'none', width: '0px', marginTop: '10px' }
      }}>
        <Box
          component="img"
          sx={{
            display: 'block',
            width: '140px',
            '@media print': { display: 'none' }
          }}
          src="/braza-logo.svg"
          alt="Braza Checkout"
        />
        <Box
          sx={{
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
            '@media print': { display: 'none' }
          }}
        >
          <IconButton onClick={onPrint}>
            <PrintOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '3rem',
          overflowY: 'unset',
          '@media print': { overflow: 'hidden' }
        }}
      >
        {children}
      </DialogContent>
    </Dialog >
  )
}
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Skeleton,
  Toolbar
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMenuContext } from '../../../contexts/MenuContext';
import {
  CUSTOMER_REGISTER_PAGE_PATH,
  LOGIN_PAGE_PATH,
  QRCODE_PAGE_PATH,
  SALES_PAGE_PATH,
  SUCCESS_PAGE_PATH,
  VERIFIY_CPF_PAGE_PATH
} from '../../../utils/constants';
import H2 from '../../H2';
import { useSellerInfo } from '../../SellerInfo';

export type HeaderProps = {
  opened: boolean
  handleMenuAction: () => void
  width?: string | number
};

const pathValue = {
  home: '',
  sellInfo: 'Dados da compra',
  sellCheckCpf: 'CPF do Pagador',
  qrCodeInfo: 'Pagamento',
  customerInfo: 'Dados pessoais do cliente'
}

function Header({ opened, handleMenuAction, width }: HeaderProps) {
  const { pathname } = useLocation();
  const { sellerInfo } = useSellerInfo()
  const [nameOfTitle, setNameOfTitle] = useState('')
  const { showBar, handleShowBar, visibleLayout, handleVisibleLayout } = useMenuContext()

  function getConfNameAvatar(name: string) {
    return {
      sx: {
        background: '#fff3f6',
        color: '#000000'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    }
  }

  const AvatarOnMenu = () => {
    if (!sellerInfo.name || sellerInfo.name === '') {
      return (<Skeleton
        sx={{ bgClor: '#ccc' }}
        variant='circular'
        animation='pulse' />)
    }

    return (
      <Avatar
        alt={sellerInfo.name}
        {...getConfNameAvatar(sellerInfo.name)} />)
  }

  useEffect(() => {
    setNameOfTitle(pathValue.home)
    handleShowBar(true)
    handleVisibleLayout(false)
    if (pathname !== LOGIN_PAGE_PATH) {
      handleVisibleLayout(true)
    }

    if (pathname === CUSTOMER_REGISTER_PAGE_PATH) {
      setNameOfTitle(pathValue.customerInfo)
    }

    if (pathname === SUCCESS_PAGE_PATH) {
      handleShowBar(false)
    }

    if (pathname === QRCODE_PAGE_PATH) {
      setNameOfTitle(pathValue.qrCodeInfo)
    }

    if (pathname === VERIFIY_CPF_PAGE_PATH) {
      setNameOfTitle(pathValue.sellCheckCpf)
    }

    if (pathname === SALES_PAGE_PATH) {
      setNameOfTitle(pathValue.sellInfo)
    }
  }, [pathname]);

  return (
    <AppBar
      sx={{
        display: showBar ? 'flex' : 'none',
        background: '#FFF',
        width: '100%',
        borderLeft: { lg: 'none' },
        '@media print': {
          display: 'none'
        },
        boxShadow: 'none',
        borderBottom: '1px solid #ccc',
        zIndex: 9999,
        transition: 'width .1s ease-in-out'
      }}
    >
      <Toolbar disableGutters sx={{
        display: visibleLayout,
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'space-between',
        mr: '56px',
      }}>
        <Box sx={{
          display: visibleLayout,
          alignItems: 'center',
          alignContent: 'center',
          justifyItems: 'center',
        }}>
          <IconButton onClick={handleMenuAction}>
            <MenuIcon
              sx={{
                color: 'text.primary',
                fontWeight: 'bolder',
                fontSize: '2rem',
                mx: '1rem',
              }}
            />
          </IconButton>
          <Box
            component="img"
            src="/braza-logo.svg"
            sx={{
              width: '100px',
              my: '10px',
            }}
          />
          <H2 sx={{
            display: { xs: 'none', md: 'block' },
            color: '#000',
            fontWeight: '600',
            fontSize: '1rem',
            lineHeight: '21.82px',
            mx: '2.5rem',
          }}>{nameOfTitle}</H2>
        </Box>
        <AvatarOnMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;

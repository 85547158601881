import { Button, styled } from '@mui/material'

const DefaultButton = styled(Button)(({theme}) => ({
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: theme.palette.primary.dark,
  borderRadius: '6px',
  color: theme.palette.primary.dark,
  fontFamily: theme.typography?.fontFamily,
  fontWeight: '700',
  fontSize: '1.125rem',
  height: '3.5rem',
  textTransform: 'none',
}));

export default DefaultButton;
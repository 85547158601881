import { useContext } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { SnackProvider } from './components/SnackProvider';
import Home from './pages/Home';
import Login from './pages/Login';
import ThemeColor from './ThemeColor';
import UserContext from './contexts/UserContext';
import { MenuProvider } from './contexts/MenuContext';
import { SaleProvider } from './contexts/SaleContext';
import {
  CUSTOMER_REGISTER_PAGE_PATH,
  FAQ_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  QRCODE_PAGE_PATH,
  SALES_PAGE_PATH,
  SUCCESS_PAGE_PATH,
  VERIFIY_CPF_PAGE_PATH,
  WALLET_PAGE_PATH
} from './utils/constants';
import QRCode from './pages/QRCode';
import Register from './pages/Register';
import SaleCPFCheck from './pages/Sale/SaleCPFCheck';
import Sale from './pages/Sale';
import Layout from './components/Layout';
import { SellerInfoProvider } from './components/SellerInfo';
import { SuccessDialog } from './pages/QRCode/Success';
import { Help } from './pages/Help'

import './i18n';
import './App.css';
import { GeneralSellerConfigProvider } from './contexts/GeneralSellerConfigContext';

function App() {
  const navigate = useNavigate()
  const [userData, setUserData] = useContext(UserContext);
  const theme = createTheme(ThemeColor('light'));

  return (
    <UserContext.Provider value={[userData, setUserData]}>
      <GeneralSellerConfigProvider>
        <MenuProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackProvider>
              <SellerInfoProvider>
                <SaleProvider>
                  <Layout>
                    <Routes>
                      <Route path={LOGIN_PAGE_PATH} element={<Login />} />
                      <Route path={WALLET_PAGE_PATH} element={<Home />} />
                      <Route path={SALES_PAGE_PATH} element={<Sale />} />
                      <Route path={HOME_PAGE_PATH} element={<Navigate to="/carteira" />} />
                      <Route path={VERIFIY_CPF_PAGE_PATH} element={<SaleCPFCheck onClose={() => { }} />} />
                      <Route path={CUSTOMER_REGISTER_PAGE_PATH} element={<Register />} />
                      <Route path={SUCCESS_PAGE_PATH} element={<SuccessDialog open={true} onClose={() => navigate(WALLET_PAGE_PATH)} />} />
                      <Route path={QRCODE_PAGE_PATH} element={<QRCode />} />
                      <Route path={FAQ_PAGE_PATH} element={<Help />} />
                    </Routes>
                  </Layout>
                </SaleProvider>
              </SellerInfoProvider>
            </SnackProvider>
          </ThemeProvider>
        </MenuProvider>
      </GeneralSellerConfigProvider>
    </UserContext.Provider>
  );
}

export default App;

const FAQ = [
  {
    question: '<strong>Qual o limite por transação?</strong>',
    answer: 'O limite por transação é de <strong>USD 10.000,00</strong> ou valor equivalente em outras moedas.',
  },
  {
    question: '<strong>Qual o limite que uma pessoa possui?</strong>',
    answer: 'O limite diário é de <strong>USD 10.000,00</strong> ou equivalente por pessoa em um mesmo dia. O limite anual é por padrão de <strong>R$ 10.000,00</strong> anual.',
  },
  {
    question: '<strong>Porque um CPF pode ser recusado?</strong>',
    answer: 'Serão recusados os CPFs cujo: <ul><li>O CPF estar associado à um menor de idade</li><li>O CPF não estar válido na receita federal</li><li>O CPF ser de um residente fora do Brasil</li><li>O CPF possuir outras restrições</li><ul>',
  },
]

export const getFaq = async () => {
  try {
    return FAQ;
  } catch (error: any) {
    throw error.data.error;
  }
};
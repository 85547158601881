import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function TermsAndPrivacy() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
        maxWidth: '18.75rem',
        mx: 'auto',
        my: '1rem',
        width: '100%',
        '@media print': { display: 'none' }
      }}
    >
      <Typography
        color="#D1D1D1"
        sx={{ alignItems: 'center', display: 'flex' }}
      >
        <Box component="span" fontSize="1.125rem">
          &#169;
        </Box>
        <Box component="span" fontSize="0.875rem" ml="0.4375rem">
          {new Date().getFullYear()}
        </Box>
      </Typography>
      <Box>&nbsp;</Box>
      <Box
        component="a"
        href={process.env.REACT_APP_TERMS_URL}
        target="_blank"
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
          fontSize: '0.875rem',
          textDecoration: 'underline',
        }}
      >
        {t('terms')}
      </Box>
      <Box>&nbsp;</Box>
      <Box
        component="a"
        href={process.env.REACT_APP_PRIVACY_URL}
        target="_blank"
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
          fontSize: '0.875rem',
          textDecoration: 'underline',
        }}
      >
        {t('privacy')}
      </Box>
    </Box>
  );
}

export default TermsAndPrivacy;

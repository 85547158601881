import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import NumberStepIcon from '../NumberStepIcon';
import CustomConnector from '../CustomConnector';

interface Props {
  namespace: string;
}

const CustomStepLabel = styled(StepLabel)(() => ({
  fontSize: '1rem',
  textJustify: 'inter-word',
  textAlign: 'justify',
  fontWeight: '400',
  color: 'text.secondary',
  'strong': {
    fontWeight: '700',
  }  
}));

function Instructions({ namespace }: Props) {
  const { t } = useTranslation(namespace);

  return (
    <Box
      sx={{
        maxWidth: '400px',
        '@media print': { px: '6rem' },
      }}
    >
      <Box
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          color: 'text.secondary',
          mb: '40px',
          '@media print': { color: '#000000' },
        }}
      >
        {t('title')}
      </Box>
      <Stepper
        orientation="vertical"
        activeStep={2}
        connector={<CustomConnector />}
        sx={{
          '@media print': {
            'span': {
              color: '#000000 !important'
            }
          }
        }}>
        <Step>
          <CustomStepLabel
            StepIconComponent={NumberStepIcon}>
            <Trans t={t}>{t('step1')}</Trans>
          </CustomStepLabel>
          <StepContent></StepContent>
        </Step>
        <Step>
          <CustomStepLabel
            StepIconComponent={NumberStepIcon}>
            <Trans t={t}>{t('step2')}</Trans>
          </CustomStepLabel>
          <StepContent></StepContent>
        </Step>
        <Step>
          <CustomStepLabel
            StepIconComponent={NumberStepIcon}>
            <Trans t={t}>{t('step3')}</Trans>
          </CustomStepLabel>
          <StepContent></StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}

export default Instructions;

import { maskSohNumeros } from './masks';
import jwt_decode, { JwtPayload } from "jwt-decode";

export function checkName(name: string) {
  const regName =
    /^([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{2,}\s[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,}'?-?[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,}\s?([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,})?)/;
  let error = false;
  let checked = false;

  if (name && !regName.test(name)) {
    error = true;
  } else if (name) {
    checked = true;
  }

  return { checked, error };
}

/**
 * @author Angelo Reis <angelo.reis@3rd.braza.com.br>
 * @description Alteração na rotina de validação do e-mail. Angelo só aceitava
 * com dominios .com.br com .br etc. agora aceita .digital .tv .finance etc
 * @DataAlteracao 26.05.2023
 * @Task PAGA-765 [Checkout-web] Corrigir regex de validação do email
 */
export function checkEmail(email: string) {
  let error = false;
  let checked = false;
  const regName =
    /^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*\.\w{2,9}$/;

  if (email && !regName.test(email)) {
    error = true;
  } else if (email) {
    checked = true;
  }

  return { checked, error };
}

export function checkCPF(cpf: string) {
  let soma = 0;
  let resto;

  cpf = maskSohNumeros(cpf);

  if (cpf === '00000000000') return false;

  if (cpf.split('').every((x, _, a) => x === a[0])) return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export async function toClipboard(content: string | undefined) {
  try {
    if (content) {
      await navigator.clipboard.writeText(content)
      return true
    }
  } catch (error) {
    return false
  }
}

/**
 * Author: Angelo Reis <angelo.reis#rd.braza.com.br>
 * Alteração na forma que extrai os dados vindos do
 * access_token
 * 
 * Atividade PAGA-708
 * Data da Alteração: 18.04.2023
 */
interface JwtPayloadParsed extends JwtPayload {
  partnerId: number
  nome: string
  mySellerAdmin?: number
}

export function extractInfoAccessToken() {
  const accessToken = localStorage.getItem('access_token')
  if (localStorage.hasOwnProperty('access_token') && accessToken !== '' && accessToken !== null) {
    const decodedToken = jwt_decode<JwtPayloadParsed>(accessToken)
    const { partnerId: sellerId, nome: nameSeller, sub: sellerCashierId, mySellerAdmin } = decodedToken

    localStorage.setItem('seller_cashier_id', sellerCashierId || '');
    localStorage.setItem('seller_cashier_name', nameSeller);
    sessionStorage.setItem('seller_id', sellerId.toString())
    sessionStorage.setItem('mySellerAdmin', mySellerAdmin?.toString() || '0')
  }
}

export const MAX_ITEMS_PER_PAGE = 10
import { Box } from "@mui/material";
import { ReactNode } from "react";

type LabelReceiptProps = {
  children: ReactNode
}

export function Label({ children }: LabelReceiptProps) {
  return (
    <Box
      component="span"
      sx={{
        fontWeight: '400',
        fontSize: '14px',
        textAlign: 'left',
        color: 'text.primary',
        '@media print': { color: '#000' },
        lineHeight: '25px',
      }}
    >
      {children}
    </Box>
  );
}
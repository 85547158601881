import { Box } from '@mui/system';
import React from 'react';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

type stmPgnProps = {
  actualPage: number;
  pageSize?: number;
  length: number;
  nextPage(): void;
  previousPage(): void;
};

function StatementPagination({ actualPage, length, nextPage, previousPage, pageSize = 10 }: stmPgnProps) {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '5px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Box sx={{ marginRight: '10px', fontSize: '12px' }}>
        {Math.min(1 + pageSize * (actualPage - 1), length)}-
        {Math.min(actualPage * pageSize, length)} de {length}
      </Box>
      <Box sx={{ marginTop: '5px' }}>
        <ChevronLeftOutlinedIcon
          sx={{ transform: 'scale(0.9)', cursor: 'pointer' }}
          onClick={previousPage}
        />
        <ChevronRightOutlinedIcon
          sx={{ transform: 'scale(0.9)', cursor: 'pointer' }}
          onClick={nextPage}
        />
      </Box>
    </Box>
  );
}

export default StatementPagination;

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';

const CancelButton = (props: { cancel?: boolean }) => {
  const { cancel = true } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 'auto',
        my: '10px',
      }}
    >
      <PrimaryButton
        variant="text"
        onClick={() => {
          navigate('/carteira');
        }}
        sx={{ '&:hover': { background: 'transparent' } }}
      >
        {cancel
          ? t('cancelButton')
          : t('backButton')}
      </PrimaryButton>
    </Box>
  );
};

export default CancelButton;

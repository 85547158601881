import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip } from "@mui/material"
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { SALES_PAGE_PATH } from "../../utils/constants";
import Menu from ".";
import PrimaryButton from "../PrimaryButton";


export type FullMenuProps = {
  opened: boolean
  handleMenuAction: () => void
};

export const FullMenu = ({ opened, handleMenuAction }: FullMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  return (
    <Box
      sx={{
        overflow: '',
      }}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{
          paddingX: opened ? '24px' : '10px',
          marginTop: opened ? '20px' : '5px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          pb: '25px',
          pt: '15px',
        }}
      >
        <Box>
          <Tooltip title={t('sellNow')}>
            <PrimaryButton
              variant="contained"
              onClick={() => {
                if (opened) handleMenuAction();
                navigate(SALES_PAGE_PATH);
              }}
              sx={{
                width: opened ? '220px' : '56px',
                height: '56px',
                borderRadius: '12px',
                display: 'flex',
                alignItens: 'center',
                fontSize: '1rem',
                fontWeight: '600',
                lineHeight: '24px'
              }}
            >
              <SellOutlinedIcon sx={{ mr: opened ? '10px' : 'none', fontSize: '20px', transition: 'all .3s ease-in-out' }} />
              {opened ? t('sellNow') : <></>}
            </PrimaryButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ mt: '.875rem' }}>
        <Menu opened={opened} handleMenu={handleMenuAction} />
      </Box>
    </Box>
  )
}
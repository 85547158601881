import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Terms from './Terms';
import Code from './Code';
import ErrorPage from './Error';
import Expired from './Expired';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';
import CancelButton from '../../components/CancelButton';
import Limit from './Limit';
import Instructions from '../../components/Instructions';
import { useNavigate } from 'react-router-dom';
import {
  HOME_PAGE_PATH,
  SUCCESS_PAGE_PATH,
  VERIFIY_CPF_PAGE_PATH
} from '../../utils/constants';
import GoBack from '../../components/GoBack';

const QRCode = () => {
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const [expired, setExpired] = useState(false);
  const [limit, setLimit] = useState(false);
  const [durationSecs, setDurationSecs] = useState(600);

  function handlePrint() {
    window.print();
  }

  function handleError(error?: string) {
    if (error === 'expired') {
      setExpired(true);
      return;
    }

    if (error === 'limit') {
      setLimit(true);
      return;
    }

    if (error === 'mismatch') {
      setErrorText('errorMismatch');
      setError(true);
      return;
    }

    if (error === 'amountMismatch') {
      setErrorText('amountMismatch');
      setError(true);
      return;
    }

    setErrorText('');
    setError(true);
  }

  function handleSuccess() {
    setSuccess(true);
  }

  if (expired) {
    return (
      <Expired
        open={expired}
        onClose={() => {
          setExpired(false);
          navigate(HOME_PAGE_PATH)
        }}
      />
    );
  }

  if (limit) {
    return (
      <Limit
        open={limit}
        onClose={() => {
          setLimit(false);
          navigate(HOME_PAGE_PATH)
        }}
      />
    );
  }

  if (error) {
    return (
      <ErrorPage
        open={error}
        text={errorText}
        onClose={() => {
          setError(false);
          navigate(HOME_PAGE_PATH)
        }}
      />
    );
  }

  if (success) {
    navigate(SUCCESS_PAGE_PATH)
  }

  return (
    <Box>
      <Box sx={{ mt: '3rem', pl: '8rem', width: '100%', alignItems: 'flex-start' }}>
        <GoBack to={() => navigate(VERIFIY_CPF_PAGE_PATH)} />
      </Box>
      <Box
        sx={{
          mt: '-2rem',
          p: '20px',
          background: '#FFF'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '400px',
              mb: '20px',
              '@media print': { display: 'block' },
            }}
          >
            <Instructions namespace={'instructions'} />
            <Terms durationSecs={durationSecs} />
          </Box>
          <Box
            sx={{
              order: { xs: -1, lg: 'initial' },
              p: '21px',
              pb: '40px',
              minWidth: '450px',
              mx: '40px',
              mb: '10px',
              '@media print': { minWidth: '100%', mx: 'auto', p: 'auto' },
            }}
          >
            <Code
              onCode={(code) => setDurationSecs(code.ttl)}
              onError={handleError}
              onSuccess={handleSuccess}
            />
          </Box>
        </Box>
        <Box
          sx={{
            '@media print': { display: 'none' },
          }}
        >
          <CancelButton cancel={false} />
          <TermsAndPrivacy />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          right: '40px',
          top: '80px',
          '@media print': { display: 'none' },
        }}
      >
        <Tooltip title={'Imprimir QR Code da transação'}>
          <IconButton onClick={handlePrint}>
            <PrintOutlinedIcon sx={{ fill: '#000000' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default QRCode;

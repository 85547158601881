import axios from 'axios';
import { post } from './generic';

export async function doLogin(user_email: string, user_password: string) {
  const url = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

  try {
    const res = await axios.post(
      `${url}/auth/login`,
      {},
      {
        auth: {
          username: user_email,
          password: user_password,
        },
      },
    );

    if (res.data.status !== 200) {
      throw new Error('error');
    }

    localStorage.setItem('access_token', res.data.data.access_token);
    localStorage.setItem('refresh_token', res.data.data.refresh_token);
    localStorage.setItem('ttl', res.data.data.ttl);
  } catch (error: any) {
    if (error.response.status === 404) {
      throw new Error('Credenciais inválidas!');
    }
    if (
      error.response.data.error ===
      'Incorrect password or email. Remaining login attempts: 2'
    ) {
      throw new Error(
        'Credenciais inválidas. Tentativas de login restantes: 2',
      );
    }
    if (
      error.response.data.error ===
      'Incorrect password or email. Remaining login attempts: 1'
    ) {
      throw new Error(
        'Credenciais inválidas. Tentativas de login restantes: 1',
      );
    }
    if (
      error.response.data.error ===
      'Your account has been locked. Please contact our support team. Remaining login attempts: 0'
    ) {
      throw new Error(
        'Sua conta foi bloqueada. Por favor, contate nosso suporte.',
      );
    }
    if (
      error.response.data.error ===
      'Your account has been locked. Please contact our support team.'
    ) {
      throw new Error(
        'Sua conta está bloquada. Por favor, entre em contato com nosso suporte.',
      );
    }
  }
}

export async function doLogout() {
  const access_token = localStorage.getItem('access_token');

  try {
    const res = await post('/auth/logout', { access_token });

    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
    localStorage.setItem('ttl', '');
    localStorage.setItem('seller_cashier_id', '');
    localStorage.setItem('seller_cashier_name', '');
    localStorage.setItem('isInvoiceMdr','');
    sessionStorage.setItem('seller_id', '')
    sessionStorage.setItem('mySellerAdmin','')

    return res.data;
  } catch (error) {
    throw new Error('');
  }
}

export async function doRefresh() {
  const refresh_token = localStorage.getItem('refresh_token');
  
  try {
    const res = await post('/auth/refresh', {
      refresh_token,
    });

    localStorage.setItem('access_token', res.data.data.access_token);
    localStorage.setItem('refresh_token', res.data.data.refresh_token);
    localStorage.setItem('ttl', res.data.data.ttl);
    
    return res.data;
  } catch (error) {
    throw new Error('');
  }
}

import { createContext, ReactElement, useContext, useState } from 'react';
import { CircularProgress, Modal } from '@mui/material';

export interface LoadingInfo {
  show: () => void;
  hide: () => void;
}

const LoadingInfoDefault = { show: () => {}, hide: () => {} };

const LoadingContext = createContext<LoadingInfo>(LoadingInfoDefault);

export function useLoading() {
  return useContext(LoadingContext);
}

interface Props {
  children: React.ReactNode;
}

function LoadingProvider({ children }: Props): ReactElement {
  const [open, setOpen] = useState(false);

  function show() {
    setOpen(true);
  }

  function hide() {
    setOpen(false);
  }

  return (
    <LoadingContext.Provider value={{ show, hide }}>
      {children}
      <Modal open={open}>
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-100px, -100px)',
            outline: 'none',
          }}
          size="100px"
        />
      </Modal>
    </LoadingContext.Provider>
  );
}

export default LoadingProvider;

import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { intlCurrencyMask } from "../../utils/currency"
import PrimaryButton from "../PrimaryButton"

type DetailsModalProps = {
  isOpenDialog: boolean
  onCloseModal: () => void
  cambio: number
  custo: number
  iof: number
}

export const DetailsSaleModal = ({ isOpenDialog, onCloseModal, cambio, custo, iof }: DetailsModalProps) => {
  const { t } = useTranslation('qrcode')
  return (
    <Dialog open={isOpenDialog} onClose={onCloseModal} aria-label="openned-details-sale-modal">
      <DialogContent>
        <Box sx={{ px: '1.5rem', pt: '2rem' }} >
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: '400',
              lineHeight: '2rem',
              textAlign: 'center',
              pb: '1rem'
            }}>
            {t('titleDetailsSale')}
          </Typography>

          <Grid
            data-testid="data-details-sale-content"
            container
            sx={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              color: 'text.secondary',
              width: '260px',
              px: '10px',
              pb: '1.5rem',
              '@media print': { color: '#000', px: '0px', fontSize: '12px' },
            }}
          >
            <Grid item xs={7}>
              <Box>{t('price')}</Box>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Box data-testid="data-value-cambio">R$ {intlCurrencyMask(cambio.toFixed(2), 'pt-BR')}</Box>
            </Grid>
            <Grid item xs={7}>
              <Box>{t('cost')}</Box>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Box data-testid="data-value-custo">R$ {intlCurrencyMask(custo.toFixed(2), 'pt-BR')}</Box>
            </Grid>
            <Grid item xs={7}>
              <Box>{t('iof')}</Box>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Box data-testid="data-value-iof">R$ {intlCurrencyMask(iof.toFixed(2), 'pt-BR')}</Box>
            </Grid>
          </Grid>

          <PrimaryButton
            data-testid="button-action-close-details-sale-modal"
            variant="contained"
            fullWidth
            onClick={onCloseModal}>
            Entendi
          </PrimaryButton>

        </Box>
      </DialogContent>
    </Dialog>
  )
}
import { Box } from '@mui/material';

interface MainPanelProps {
  children: JSX.Element[] | JSX.Element;
}

function MainPanel(props: MainPanelProps) {
  const { children } = props;
  return (
    <Box
      sx={{
        width: '100%',
        minWidth: '50%',
      }}
    >
      {children}
    </Box>
  );
}

export default MainPanel;

import { Currency } from '../services/currency';

export function intlCurrencyMask(string: string, intlLocale = 'pt-BR') {
  string = string.replace('.', '').replace(',', '').replace(/\D/g, '');
  if (string.length < 1) {
    return string;
  }
  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat(intlLocale, options).format(
    parseFloat(string) / 100,
  );
  return result;
}

export function getCurrencySimbol(currencies: Currency[], iso: string) {
  const cur = currencies.find((c) => c.iso.toLowerCase() === iso.toLowerCase());
  return cur?.symbol ?? '$';
}

export function getCurrencyCountry(currencies: Currency[], iso: string) {
  const cur = currencies.find((c) => c.iso.toLowerCase() === iso.toLowerCase());
  return cur?.country;
}

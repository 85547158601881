import { MouseEvent, useContext, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow
} from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { TRANSACTION_STATUS, Transaction } from '../../services/transactions';
import StatusField from './StatusField';
import { useSellerInfo } from '../SellerInfo';
import {
  getCurrencyCountry,
  getCurrencySimbol,
  intlCurrencyMask,
} from '../../utils/currency';
import { StatementContext } from '.';
import { consultQuotation } from '../../services/quote';
import Receipt, { ReceiptDataProps } from '../Receipt';
import { ModalReceipt } from '../Receipt/modal';
import { useSnack } from '../SnackProvider';
import { useMenuContext } from '../../contexts/MenuContext';
import { useGeneralConfigSeller } from '../../contexts/GeneralSellerConfigContext';

type MenuProps = {
  anchorEl: HTMLElement | null
  isOpen: boolean
  OnClose: () => void
  onPrintInvoice: () => void
  onPrintInvoicePartner: () => void
  onPrintQrCode: () => void
  isExpired: boolean
  statusInvoice: string
  printInvoiceSeller: boolean
}

function ActionMenu({ isOpen, OnClose, anchorEl, onPrintInvoice, onPrintInvoicePartner, onPrintQrCode, isExpired = false, statusInvoice, printInvoiceSeller }: MenuProps) {

  const isDisabledPrintReceitOption = useRef(true)
  const isDisabledPrintReceitPartnerOption = useRef(true)
  const isDisablePrintQrCodeOption = useRef(true)

  const isMdrInvoice = localStorage.getItem('isInvoiceMdr') === 'false' ? false : true
  
  if (statusInvoice === TRANSACTION_STATUS.APPROVED || statusInvoice === TRANSACTION_STATUS.PROCESSED) {
    isDisabledPrintReceitOption.current = false

    if (printInvoiceSeller) isDisabledPrintReceitPartnerOption.current = false
  }

  if (statusInvoice === TRANSACTION_STATUS.PENDING) {
    isDisablePrintQrCodeOption.current = false
  }

  if (statusInvoice === TRANSACTION_STATUS.PENDING && isExpired) {
    isDisablePrintQrCodeOption.current = true
  }

  return (
    <Menu
      open={isOpen}
      onClose={OnClose}
      anchorEl={anchorEl}
    >
      <MenuItem disabled={isDisablePrintQrCodeOption.current} onClick={onPrintQrCode} sx={{ display: 'none' }}>
        <ListItemIcon>
          <QrCodeScannerIcon />
        </ListItemIcon>
        <ListItemText>
          QR Code
        </ListItemText>
      </MenuItem>
      <MenuItem
        disabled={isDisabledPrintReceitOption.current}
        onClick={onPrintInvoice}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText>
          Via Cliente
        </ListItemText>
      </MenuItem>
      {
        isMdrInvoice &&
          <MenuItem
            disabled={isDisabledPrintReceitPartnerOption.current}
            onClick={onPrintInvoicePartner}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText>
              Via Estabelecimento
            </ListItemText>
          </MenuItem>
      }
    </Menu>
  )
}

function HiddenBox() {
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        color: 'text.disabled',
        width: '100px',
        height: '20px',
        borderRadius: '4px',
        backgroundColor: 'text.disabled',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
      }}
    />
  );
}

interface Props {
  data: Transaction;
  index: number;
  handleShowValue?: (index: number) => void;
  handleMenuOpen?: (e: any, index: number) => void;
  handleMenuClose?: (index: number) => void;
}

function StatementItem({ index, data }: Props) {
  const snack = useSnack()
  const { invoicePartnerEnable } = useGeneralConfigSeller()
  const { sellerInfo } = useSellerInfo();
  const { hide } = useContext(StatementContext);
  const { handleShowBar } = useMenuContext()
  const [dataRowReceipt, setDataRowReceipt] = useState<ReceiptDataProps>({} as ReceiptDataProps)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenPartner, setModalOpenPartner] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const printInvoice = async (quoteId: string, partner?: boolean) => {
    try {
      const quotationResponse = await consultQuotation(quoteId)
      if (quotationResponse) {
        setDataRowReceipt({
          confirmationDatetime: new Date(data.datetime),
          nameCostumer: data.name,
          currencyAmountBRL: quotationResponse.BRLquantity,
          nameSeller: sellerInfo.name,
          currenyCostumerIso: data.currency,
          currencyAmountFNG: quotationResponse.FGNquantity,
          quoteBRL: quotationResponse.quote,
          fee: quotationResponse.fee,
          iof: quotationResponse.iof,
          mdr: quotationResponse.mdr,
          pixDateTime: new Date(data.datetime),
          documentCPFBRL: data.document,
          pixTransaction: data.transaction
        })
      }
      if (partner) {
        setModalOpenPartner(true)
      } else {
        setModalOpen(true)
      }
      handleShowBar(false)
    } catch (error) {
      snack.genericError()
    }
  }

  const handleClickMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => setAnchorEl(null)
  const handleCloseModal = () => {
    setModalOpen(false)
    handleShowBar(true)
  }

  const handleCloseModalPartner = () => {
    setModalOpenPartner(false)
    handleShowBar(true)
  }

  const isOdd = (index: number) => (index & 1) === 1

  return (
    <TableRow key={index}
      sx={{
        borderBottom: 'hidden',
        background: isOdd(index) ? 'rgba(209,209,209,.2)' : '#ffffff',
        height: '36px',
        'td': {
          py: '8px',
          px: '16px'
        }
      }}>
      <TableCell>{new Date(data.datetime).toLocaleString()}</TableCell>
      <TableCell>
        <StatusField item={data} />
      </TableCell>
      <TableCell
        sx={{
          display: {
            xs: 'none',
            md: 'table-cell',
          },
        }}
      >
        {data.type || 'Pix'}
      </TableCell>
      <TableCell>{hide ? <HiddenBox /> : data.name}</TableCell>
      <TableCell
        sx={{
          display: {
            xs: 'none',
            md: 'table-cell',
          },
        }}
      >
        {hide ? <HiddenBox /> : data.document}
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        {hide ? (
          <HiddenBox />
        ) : (
          data.amount &&
          `${getCurrencySimbol(
            sellerInfo.currencies,
            data.currency,
          )} ${intlCurrencyMask(
            data.amount ?? '0',
            getCurrencyCountry(sellerInfo.currencies, data.currency),
          )}`
        )}
      </TableCell>
      <TableCell
        sx={{
          display: {
            xs: 'none',
            md: 'table-cell',
          },
        }}
      >
        {data.transaction}
      </TableCell>
      <TableCell>
        <IconButton onMouseEnter={(e) => handleClickMenuOpen(e)}>
          <MoreVertIcon sx={{ color: '#4D9AEC' }} />
        </IconButton>
        <ActionMenu
          key={`Action-menu-${index}-document-${data.document}`}
          OnClose={handleCloseMenu}
          isOpen={isOpen}
          anchorEl={anchorEl}
          onPrintInvoice={() => printInvoice(data.quoteId)}
          onPrintInvoicePartner={() => printInvoice(data.quoteId, true)}
          onPrintQrCode={() => { }}
          isExpired={data.expired}
          statusInvoice={data.status}
          printInvoiceSeller={invoicePartnerEnable} />
        <ModalReceipt open={modalOpen} onClose={handleCloseModal} key={`${index}-customer`}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Receipt
              data={dataRowReceipt}
              fromSale={false}
              key={`ModalReceipt-A-${dataRowReceipt.pixTransaction}-date${dataRowReceipt.confirmationDatetime}-index-${index}`}
              onClose={handleCloseModal} />
          </Box>
        </ModalReceipt>
        <ModalReceipt open={modalOpenPartner} onClose={handleCloseModalPartner} key={`${index}-partner`}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Receipt
              data={dataRowReceipt}
              fromSale={false}
              fromSellerReceipt={true}
              key={`ModalReceipt-B-${dataRowReceipt.pixTransaction}-date${dataRowReceipt.confirmationDatetime}-index-${index}`}
              onClose={handleCloseModalPartner} />
          </Box>
        </ModalReceipt>
      </TableCell>
    </TableRow>
  );
}

export default StatementItem;

import React from 'react';

export function leech(v: string) {
  v = v.replace(/o/gi, '0');
  v = v.replace(/i/gi, '1');
  v = v.replace(/z/gi, '2');
  v = v.replace(/e/gi, '3');
  v = v.replace(/a/gi, '4');
  v = v.replace(/s/gi, '5');
  v = v.replace(/t/gi, '7');
  return v;
}

export function maskFone(v: string) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/^(\d\d)(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d{5})(\d)/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
}

export function maskCpf(v: string) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  return v;
}

export function hideCpf(v: string) {
  v = v.replace(/^(.{3})/, '***');
  v = v.replace(/(.{2})$/, '**');
  return v;
}

export function maskCep(v: string) {
  v = v.replace(/[^0-9]/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{5})(\d)/, '$1-$2'); //Esse é tão fácil que não merece explicações
  return v;
}

export function maskSohNumeros(v: string) {
  return v.replace(/\D/g, '');
}

export function maskData(v: string) {
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{2})(\d)/, '$1/$2');
  v = v.replace(/(\d{2})(\d)/, '$1/$2');
  v = v.replace(/(\d{2})(\d{2})$/, '$1$2');
  return v;
}

export function maskCC(v: string) {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{4})(\d)/g, '$1 $2');
  v = v.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
  v = v.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
  return v;
}

/**
 * Filtra caracter e retorna uma string contendo apenas letras e número
 * @param v string
 * @returns string
 */
export function alphaNumberOnly(v: string) {
  return v.replace(/[^a-zA-Z0-9\+\,\.\-\s\*\/]/gi,'');
}

/**
 * Formats to currency
 * @param e string value with 2 decimal
 * @returns the formated string
 */
export function maskCurrency(e: string) {
  let valueInput = e;
  valueInput = valueInput.replace(/\D/g, '');
  valueInput = valueInput.replace(/(\d)(\d{2})$/, '$1,$2');
  valueInput = valueInput.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return valueInput;
}

/**
 * Return the formated duration.
 *
 * @param d miliseconds
 * @returns [hh:]mm:ss
 */
export function formatDuration(d: number) {
  let sec_num = d / 1000;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
  let s_hours = '' + hours;
  let s_minutes = '' + minutes;
  let s_seconds = '' + seconds;

  if (hours < 10) {
    s_hours = '0' + s_hours;
  }

  if (minutes < 10) {
    s_minutes = '0' + s_minutes;
  }

  if (seconds < 10) {
    s_seconds = '0' + s_seconds;
  }

  let response = '';
  if (hours) {
    response += s_hours + ':';
  }
  response += s_minutes + ':' + s_seconds;

  return response;
}

export function renderHTML(escapedHTML: string) {
  return React.createElement('div', {
    dangerouslySetInnerHTML: { __html: escapedHTML },
  });
}

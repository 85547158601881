import { Box, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import { renderHTML } from '../../utils/masks';
import { HOME_PAGE_PATH, SALES_PAGE_PATH } from '../../utils/constants';

interface ErrorProps {
  open: boolean;
  onClose: () => void;
}

function Expired({ open, onClose }: ErrorProps) {
  const { t } = useTranslation('qrcode');
  const navigate = useNavigate();

  return (
    <Drawer
      PaperProps={{
        sx: { width: '38.125rem' },
      }}
      anchor="right"
      disableEscapeKeyDown
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          m: '40px',
          my: 'auto',
        }}
      >
        <Box
          sx={{
            width: '315px',
            m: 'auto',
          }}
        >
          <Box
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '33px',
              textAlign: 'center',
              color: 'warning.dark',
            }}
          >
            {t('expiredTitle')}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              my: '40px',
            }}
          >
            <Box component="img" src="/alert.svg" />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              mb: '60px',
            }}
          >
            {renderHTML(t('expired'))}
          </Box>
          <Box>
            <PrimaryButton
              variant="outlined"
              fullWidth
              size="large"
              sx={{
                textTransform: 'none',
                fontSize: '18px',
                fontWeight: '600',
                mb: '20px',
              }}
              onClick={() => {
                navigate(HOME_PAGE_PATH);
              }}
            >
              {t('goBack', { ns: '' })}
            </PrimaryButton>
          </Box>
          <Box>
            <PrimaryButton
              variant="contained"
              fullWidth
              size="large"
              sx={{
                textTransform: 'none',
                fontSize: '18px',
                fontWeight: '600',
              }}
              onClick={() => {
                navigate(SALES_PAGE_PATH);
              }}
            >
              {t('new')}
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default Expired;

import { Box } from '@mui/material';
import MainPanel from './MainPanel';
import Header from './Header';
import { useMenuContext } from '../../contexts/MenuContext';
import LeftPanel from './LeftPanel';

interface LayoutProps {
  children: JSX.Element[] | JSX.Element;
}

function Layout({ children }: LayoutProps) {

  const { opened, handleCollapseMenu } = useMenuContext()

  return (
    <Box display="flex">
      <Header width={opened ? "16.75rem" : "87px"} opened={opened} handleMenuAction={handleCollapseMenu} />
      <LeftPanel  width={opened ? "16.75rem" : "87px"} opened={opened} handleMenuAction={handleCollapseMenu} />
      <MainPanel>{children}</MainPanel>
    </Box>
  );
}

export default Layout;

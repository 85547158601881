import { Box, Stack, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { Transaction, TRANSACTION_STATUS } from '../../services/transactions';

function StatusField(props: { item: Transaction }) {
  const { item } = props;
  const { t } = useTranslation('status');

  let color = 'warning.dark';
  let text = t('fail');
  let title = '';
  let icon = <InfoOutlinedIcon sx={{ color, transform: 'scale(0.9)' }} />;
  let status =
    item.status === TRANSACTION_STATUS.PENDING && item.expired
      ? TRANSACTION_STATUS.EXPIRED
      : item.status;

  switch (status) {
    case TRANSACTION_STATUS.APPROVED:
      color = 'success.main';
      text = t('success');
      title = t('success_tip');
      icon = <CheckIcon sx={{ color, transform: 'scale(0.9)' }} />;
      break;
    case TRANSACTION_STATUS.REFOUNDED:
    case TRANSACTION_STATUS.REFOUNDFAIL:
      color = 'error.main';
      text = t('fail');
      title = t('fail_tip');
      icon = <ErrorOutlineIcon sx={{ color, transform: 'scale(0.9)' }} />;      
      break;
    case TRANSACTION_STATUS.EXPIRED:
      color = 'error.main';
      text = t('fail');
      title = t('expired_tip');
      icon = <ErrorOutlineIcon sx={{ color, transform: 'scale(0.9)' }} />;
      break;
    case TRANSACTION_STATUS.PENDING:
      text = t('pending');
      title = t('pending_tip');
      break;
    case TRANSACTION_STATUS.PROCESSED:
      color = 'success.main';
      text = t('processed');
      title = t('processed_tip');
      icon = <CheckIcon sx={{ color, transform: 'scale(0.9)' }} />;
      break;
  }

  return (
    <Box
      sx={{
        borderRadius: '6px',
        px: '2px',
        height: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      <Tooltip title={title}>
        <Stack direction="row" alignItems="center">
          {icon}
          <Box
            sx={{
              color,
              fontSize: '12px',
              whiteSpace: 'nowrap',
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
              },
              ml: '3px'
            }}
          >
            {text}
          </Box>
        </Stack>
      </Tooltip>
    </Box>
  );
}

export default StatusField;

import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Sale from '../Sale';
import SaleCPFCheck from '../Sale/SaleCPFCheck';
import { Help } from '../Help';
import QRCode from '../QRCode';
import Register from '../Register';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_REGISTER_PAGE_PATH, FAQ_PAGE_PATH, HOME_PAGE_PATH, QRCODE_PAGE_PATH, SALES_PAGE_PATH, VERIFIY_CPF_PAGE_PATH } from '../../utils/constants';
import { extractInfoAccessToken } from '../../utils/validations';

function Home() {
  const { t } = useTranslation('qrcode');
  const navigate = useNavigate();
  const [_limitMessageOpen, setLimitMessageOpen] = useState<boolean>(false);
  const [_limitMessage, setLimitMessage] = useState('');

  const handleOnCloseSaleCPFCheck = (error?: string) => {
    if (error === 'limit') {
      setLimitMessage(t('limitClient'));
      setLimitMessageOpen(true);
    } else if (error === 'daily_limit') {
      setLimitMessageOpen(true);
      setLimitMessage(t('limitDaily'));
    }

    navigate(SALES_PAGE_PATH);
  }

  useEffect(()=>{
    extractInfoAccessToken()
  },[])

  return (
    <Routes>
      <Route path={HOME_PAGE_PATH} element={<Dashboard />} />
      <Route path={SALES_PAGE_PATH} element={<Sale />} />
      <Route path={VERIFIY_CPF_PAGE_PATH} element={<SaleCPFCheck onClose={handleOnCloseSaleCPFCheck} />} />
      <Route path={CUSTOMER_REGISTER_PAGE_PATH} element={<Register />} />
      <Route path={QRCODE_PAGE_PATH} element={<QRCode />} />
      <Route path={FAQ_PAGE_PATH} element={<Help />} />
    </Routes>
  );
}

export default Home;

import { StepConnector } from '@mui/material';

function CustomConnector() {
  return (
    <StepConnector
      sx={{
        pl: '5px',
        '.MuiStepConnector-line': {
          height: '10px',
          borderLeftStyle: 'none',
        },
      }}
    />
  );
}

export default CustomConnector;

import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
  InputBase,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutline from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { getFaq } from '../../services/faq';
import { renderHTML } from '../../utils/masks';
import { useTranslation } from 'react-i18next';

interface FaqItem {
  question: string;
  answer: string;
}

export const Help = () => {
  const { t } = useTranslation('faq');
  const [listFaq, setListFaq] = useState<Array<FaqItem>>();
  const [listFaqPesquisa, setListFaqPesquisa] = useState<Array<FaqItem>>();
  const [input, setInput] = useState('');

  const handlePesquisa = (value: string) => {
    setInput(value);
    if (!listFaq || !listFaq.length) return;

    if (!value) {
      setListFaqPesquisa(listFaq);
      return;
    }

    const filtered = listFaq.filter((f) =>
      f.question.toLowerCase().includes(value.toLowerCase()),
    );
    setListFaqPesquisa(filtered);
  };

  const handleGetFaq = async () => {
    try {
      const list = await getFaq();
      setListFaq(list);
      setListFaqPesquisa(list);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetFaq();
  }, []);

  return (
    <Box sx={{ marginX: { xs: '10px', md: '100px' }, marginTop: '40px' }}>
      <Box
        sx={{
          marginBottom: '12px',
          color: '#223A78',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '22px',
        }}
      >
        {t('helpQuestion')}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '50px',
        }}
      >
        <Paper
          component="form"
          sx={{
            display: 'flex',
            width: '100%',
            outlineColor: 'GrayText',
            backgroundColor: 'white',
          }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t('placeholder')}
            inputProps={{ 'aria-label': t('placeholder') }}
            value={input}
            onChange={(e) => handlePesquisa(e.target.value)}
          />
        </Paper>
      </Box>
      {listFaqPesquisa &&
        listFaqPesquisa.map((e) => (
          <Accordion key={e.question}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontSize: '16px', paddingY: '10px' }}>
                {renderHTML(e.question)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: '16px' }}>
                {renderHTML(e.answer)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      <Box
        sx={{
          backgroundColor: 'background.main',
          width: '100%',
          borderRadius: '12px',
          marginTop: '40px',
          marginBottom: '50px',
          display: 'flex',
          justifyContent: 'space-evenly',
          padding: '30px',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'start' },
          }}
        >
          <Box
            sx={{
              fontSize: '20px',
              color: 'white',
              marginBottom: '5px',
              marginTop: '10px',
            }}
          >
            {t('phone')}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <PhoneIcon
              sx={{
                color: 'white',
                marginBottom: '5px',
                marginRight: '10px',
              }}
            />

            <Box
              sx={{
                color: 'white',
                textDecoration: 'underline',
                marginBottom: '5px',
                margintLeft: '10px',
              }}
            >
              {'+55 (45) 4042-0770'}
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: '14px',
              color: 'white',
              width: '203px',
              marginBottom: '5px',
            }}
          >
            {t('worktime')}
          </Box>
        </Box>

        <Box
          sx={{
            borderLeft: '1px solid',
            borderColor: 'background.default',
            height: '100px',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'background.default',
            width: '100%',
            display: { xs: 'block', sm: 'none' },
            marginY: '10px',
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'start' },
          }}
        >
          <Box sx={{ fontSize: '20px', color: 'white', marginBottom: '5px' }}>
            {'E-mail'}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <MailOutline
              sx={{
                color: 'white',
                marginBottom: '5px',
                marginRight: '10px',
              }}
            />

            <Box
              sx={{
                color: 'white',
                textDecoration: 'underline',
                marginBottom: '10px',
                margintLeft: '10px',
              }}
            >
              {'suporte@brazaon.com.br'}
            </Box>
          </Box>

          <Box sx={{ fontSize: '20px', color: 'white', marginBottom: '5px' }}>
            {'Whatsapp'}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <WhatsAppIcon
              sx={{
                color: 'white',
                marginBottom: '5px',
                marginRight: '10px',
              }}
            />

            <Box
              sx={{
                color: 'white',
                textDecoration: 'underline',
                marginBottom: '5px',
                margintLeft: '10px',
              }}
            >
              {'+55 (41) 94042-0770'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import { PaletteMode } from '@mui/material';

const ThemeColor = (mode: PaletteMode) => ({
  typography: {
    fontFamily: [
      '"Nunito Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: '#590029',
            dark: '#590029',
            contrastText: '#FFF',
          },
          error: {
            main: '#FF0000',
          },
          warning: {
            main: '#FFDA44',
            dark: '#F8953C',
            contrastText: '#FFF',
          },
          success: {
            main: '#3CC13B',
            dark: '#0CAF59',
          },
          text: {
            primary: 'rgba(0, 0, 0, 0.8)',
            secondary: '#5F5F5F',
            disabled: 'rgba(95, 95, 95, 0.25)',
          },
          background: {
            default: '#FFFFFF',
            paper: '#F6FAFE',
          },
        }
      : {
        primary: {
          main: '#a6ffd6',
          dark: '#a6ffd6',
          contrastText: '#000',
        },
        error: {
          main: '#00ffff',
        },
        warning: {
          main: '#FFDA44',
          dark: '#F8953C',
          contrastText: '#FFF',
        },
        success: {
          main: '#c33ec4',
          dark: '#f350a6',
        },
        text: {
          primary: 'rgba(255, 255, 255, 0.8)',
          secondary: '#a0a0a0',
          disabled: 'rgba(160, 160, 160, 0.25)',
        },
        background: {
          default: '#000000',
          paper: '#090501',
        },
      }),
  },
});

export default ThemeColor;

import { ReactElement } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { doLogout } from './services/auth';
import { DASHBOARD_PAGE_PATH, FAQ_PAGE_PATH, LOGIN_PAGE_PATH } from './utils/constants';

export interface NavItem {
  name: string;
  icon: ReactElement;
  path?: string;
  task?: () => void;
}

const nav: NavItem[] = [
  { name: 'home', path: DASHBOARD_PAGE_PATH, icon: <HomeIcon sx={{fontSize: '20px'}} /> },
  { name: 'faq', path: FAQ_PAGE_PATH, icon: <HelpOutlineIcon sx={{fontSize: '20px'}} /> },
  {
    name: 'quit',
    path: LOGIN_PAGE_PATH,
    task: async () => {
      try {
        await doLogout();
      } catch (err) {}
    },
    icon: <LogoutOutlinedIcon />,
  }
];

export default nav;

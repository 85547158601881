import { useState } from 'react';
import SaleForm from './SaleForm';
import Limit from './Limit';

const Sale = () => {
  const [limitMessageOpen, setLimitMessageOpen] = useState<boolean>(false);
  const [limitMessage, setLimitMessage] = useState('');

  return (
    <>
      <SaleForm />
      <Limit
        message={limitMessage}
        open={limitMessageOpen}
        onClose={() => setLimitMessageOpen(false)}
      />
    </>
  );
};

export default Sale;

import { useState, useEffect, createContext } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Table,
  TableBody,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import StatementItem from './StatementItem';
import StatementPagination from './StatementPagination';
import StatementHeader from './StatementHeader';
import {
  getStatusText,
  getTransactions,
  Transaction,
} from '../../services/transactions';
import FilterBox from '../FilterBox';
import { MAX_ITEMS_PER_PAGE } from '../../utils/validations';
import { useGeneralConfigSeller } from '../../contexts/GeneralSellerConfigContext';

export const StatementContext = createContext<{ hide: boolean }>({
  hide: true,
});

interface Filter {
  id: number
  name: string
  show: string
}

const mySellerAdminPermission = sessionStorage.hasOwnProperty('mySellerAdmin') ? Boolean(Number(sessionStorage.getItem('mySellerAdmin'))) : false

const mockedFilters = [
  { id: 1, name: "Tudo", show: 'flex' },
  { id: 2, name: "Pix", show: 'flex' },
  { id: 3, name: "Aprovado", show: 'flex' },
  { id: 4, name: "Cancelado", show: 'flex' },
  { id: 5, name: "Em andamento", show: 'flex' },
  { id: 6, name: "Minhas vendas", show: mySellerAdminPermission ? 'flex' : 'none'}
]

function Statement() {
  const { t } = useTranslation('statement');
  const { handleVisbilityInvoicePartner } = useGeneralConfigSeller()
  const { t: tsts } = useTranslation('status');
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [transactionsForFilter, setTransactionsForFilter] = useState<
    Array<{ key: string; value: Transaction }>
  >([]);
  const [_totalTransactions, setTotalTransactions] = useState<
    Array<Transaction>
  >([]);
  const [actualTransactions, setActualTransactions] = useState<
    Array<Transaction>
  >([]);
  const [actualPage, setActualPage] = useState(1);
  const [search, setSearch] = useState('');
  const [hide, setHide] = useState(true);
  const [filters, _setFilters] = useState<Filter[]>(mockedFilters)
  const [selectedFilter, setSelectedFilter] = useState<Filter>(mockedFilters[0])

  useEffect(() => {
    handleFilterChange()
  }, [selectedFilter, search])

  function handleFilterChange() {
    const sellerCashierId = localStorage.hasOwnProperty('seller_cashier_id') ? localStorage.getItem('seller_cashier_id') : ''
    const filtered = transactionsForFilter.filter((ti) => {
      let valid = false
      if (selectedFilter.id === 1) valid = true
      else if ((selectedFilter.id === 2 && ti.key.includes('pix')) ||
        (selectedFilter.id === 3 && ti.key.includes('aprovada')) ||
        (selectedFilter.id === 4 && ti.key.includes('cancelada')) ||
        (selectedFilter.id === 5 && ti.key.includes('em andamento')) ||
        (selectedFilter.id === 6 && ti.value.sellerCashierId === Number(sellerCashierId))) {
        valid = true
      }

      return valid ? ti.key.includes(search.toLowerCase()) : false
    }).map(f => f.value)

    setTransactions(filtered)
    setActualTransactions(filtered.slice(0, MAX_ITEMS_PER_PAGE))
    setActualPage(1)
  }

  async function getTransactionsOnBack() {
    try {
      const { checkout_history } = await getTransactions();
      if (localStorage.hasOwnProperty('isInvoiceMdr')) {
        const isEnable = localStorage.getItem('isInvoiceMdr') === 'false' ? false : true
        handleVisbilityInvoicePartner(isEnable)        
      }

      setTotalTransactions(checkout_history);
      setTransactions(checkout_history);
      setActualTransactions(checkout_history.slice(0, MAX_ITEMS_PER_PAGE));

      const filterable = checkout_history.map((value: Transaction) => {
        let obj = {
          ...value,
          dt: new Date(value.datetime).toLocaleString(),
          txt: getStatusText(value, tsts),
        };
        const key = JSON.stringify(Object.values(obj)).toLowerCase();
        return { key, value };
      });
      setTransactionsForFilter(filterable);
    } catch (error) { }
  }

  useEffect(() => {
    getTransactionsOnBack();
    const interval = setInterval(() => getTransactionsOnBack(), 60000);
    return () => clearInterval(interval);
  }, []);

  function handleSearch(value: string) {
    setSearch(value);

    const filtered = transactionsForFilter.filter((ti) => {
      return ti.key.includes(value.toLowerCase());
    });
    setTransactions(filtered.map((f) => f.value));
    setActualTransactions(filtered.map((f) => f.value).slice(0, MAX_ITEMS_PER_PAGE));
  }

  const Paginate = (
    array: Array<any>,
    page_size: number,
    page_number: number,
  ) => array.slice((page_number - 1) * page_size, page_number * page_size);

  const nextPage = () => {
    if (actualPage < Math.ceil(transactions.length / MAX_ITEMS_PER_PAGE)) {
      setActualTransactions(Paginate(transactions, MAX_ITEMS_PER_PAGE, actualPage + 1));
      setActualPage(actualPage + 1);
    }
  };

  const previousPage = () => {
    if (actualPage > 1) {
      setActualTransactions(Paginate(transactions, MAX_ITEMS_PER_PAGE, actualPage - 1));
      setActualPage(actualPage - 1);
    }
  };

  return (
    <StatementContext.Provider value={{ hide }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          m: '20px',
          mt: { xs: '70px', sm: '80px', md: '90px' },
          paddingRight: { xs: '5px', sm: '10px', md: 'none', lg: '30px' },
          paddingLeft: { xs: '5px', sm: '10px', md: '30px', lg: '120px' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            mt: { sm: '40px', md: 'auto' }
          }}>
          <Box
            sx={{
              color: 'text.primary',
              fontWeight: 700,
              fontSize: '18px',
              order: { xs: 1, sm: 1, md: 0 },
              pr: { sm: '20px', md: '40px' },
              pt: { sm: '20px', md: '0' }
            }}
          >
            {t('title')}
            <IconButton
              title={hide ? 'Exibir valores' : 'Ocultar valores'}
              onClick={() => setHide((value) => !value)}
            >
              {hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              minWidth: '320px',
              maxWidth: '728px',
              width: { xs: '100%', sm: '50%', md: '50%', lg: '50%', xl: '50%' },
              outlineColor: 'GrayText',
              backgroundColor: 'white',
              background: "#F7F7F7",
              borderRadius: "6px",
              justifyContent: 'center'
            }}
          >
            <IconButton sx={{ p: '10px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1, width: '100%' }}
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search && (
              <IconButton onClick={() => setSearch('')} sx={{ p: '10px' }}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row'
          },
          alignItems: 'center',
          flexWrap: 'wrap',
          my: '20px'
        }}>
          {
            filters.map(filter => <FilterBox
              text={filter.name}

              click={() => {
                const newFilter = filters.find(f => f.id === filter.id)
                newFilter && setSelectedFilter(newFilter)
              }}
              selected={selectedFilter.id === filter.id}
              key={filter.id}
              sx={{ display: filter.show, mx: '10px', mt: { xs: '5px', lg: 0 } }}
            />)
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            paddingLeft: { xs: '9rem', sm: '0px', md: '0px', lg: '0px', xl: '0px' },
            alignItems: 'center',
          }}
        >
          <Table
            sx={{
              width: '100%',
            }}
          >
            <StatementHeader />
            <TableBody

            >
              {actualTransactions.map((data, index) => (
                <StatementItem key={data.id} data={data} index={index} />
              ))}
            </TableBody>
          </Table>
          <StatementPagination
            pageSize={MAX_ITEMS_PER_PAGE}
            actualPage={actualPage}
            length={transactions.length}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </Box>
      </Box>
    </StatementContext.Provider>
  );
}

export default Statement;

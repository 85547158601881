export default {
  translations: {
    terms: 'Termos de Uso',
    privacy: 'Privacidade',
    backButton: 'Voltar para tela inicial',
    cancelButton: 'Cancelar e voltar para tela inicial',
    goBack: 'Tela anterior',
    error: 'Houve um problema na transação. Tente novamente.',
    errorTitle: 'Falha na transação',
    errorMismatch:
      'O documento do pagador do Pix deve ser o mesmo informado na transação.',
    amountMismatch:
      'O valor recebido no Pix não coincide com o valor da transação.',
    failTryAgain: 'Não foi possível completar a operação! Tente novamente!',
    tryAgain: 'Tentar Novamente'
  },
  dashboard: {
    sellNow: 'Vender agora',
  },
  statement: {
    title: 'Transações',
    search: 'Pesquisar',
    datetime: 'DATA/HORA',
    status: 'STATUS',
    payMethod: 'PAGAMENTO',
    client: 'CLIENTE',
    document: 'CPF',
    amount: 'VALOR',
    id: 'IDENTIFICADOR',
  },
  simulation: {
    payLess: 'Compre com Braza',
    simText:
      'Em uma simulação simples, pagando com o Braza as suas compras internacionais saem com taxas mais baratas',
    pix: 'Usando Pix Braza',
    price: 'Melhor preço',
    save: 'Economize',
    cc: 'Comparando com um cartão de crédito',
    app: 'Baixe nosso app',
    msg1: 'Suas compras internacionais com menor custo. Garantido!',
    msg2: 'Compre no exterior e garanta as taxas mais baratas do Brasil!',
    footnote:
      '*Simulação com cartão de crédito tradicional utilizando cotação corrente mais IOF (6,38%) e taxa de serviço (4,00%)',
  },
  sale: {
    title: 'Vender - Braza Checkout',
    failTryAgain: 'Falha na requisição. Tente novamente.',
    sellInfo: 'Informe os dados da venda',
    currency: 'Moeda',
    select: 'Selecione',
    amount: 'Valor',
    invalidAmount: 'Valor inválido',
    transactionId: 'Número de transação',
    invalidTransactionId: 'Apenas números e letras são permitidos',
    simulate: 'Simular',
    next: 'Próximo',
    cpf: 'Qual o cpf do pagador?',
    invalid: 'CPF inválido',
    serverError: 'Falha na comunicação com o servidor. Tente novamente.',
  },
  terms: {
    attention: 'Atenção',
    att1: 'Este QR Code só pode ser pago com o CPF cadastrado.',
    att2: 'Não serão aceitos pagamentos com conta CNPJ.',
    title: 'Termos e condições',
    term1:
      'O pagamento deste QR Code só será válido por {{DurationInMinutes}} minutos',
    term2:
      'Este pagamento deverá ser aprovado e recebido quando o Banco pagador enviar o Pix!',
    term3:
      'Ao pagar o Pix, você automaticamente dará ciência e aceitará os termos desta transação e dará automaticamente o aceite nos termos de uso do Checkout da CloudBreak.',
    term4:
      'Esta é um compra internacional, sujeita à regulamentação cambial brasileira. Ao efetuar o Pix, você consente com a operação de câmbio.',
    term5: 'Esta transação será processada pela CloudBreak e seus parceiros.',
    term6:
      'Com o pagamento deste QR Code, seus dados serão gravados na transação para conferência por parte das autoridades brasileiras.',
    term7:
      'Ao realizar este pagamento, você está concordando com a possível abertura da sua conta no CloudBreak, Braza Bank e Braza UK.',
  },
  instructions: {
    title: 'Instruções para pagamento',
    step1:
      'Acesse o <strong>Internet banking</strong> ou <strong>app</strong> do banco',
    step2:
      'Na área Pix, <strong>escaneie o QR Code</strong> e efetue o pagamento',
    step3:
      '<strong>Pronto!</strong> Assim que recebermos, abrirá uma nova tela de confirmação de pagamento',
  },
  instructionsSale: {
    title: 'Instruções',
    step1:
      'Primeiro, selecione a moeda em que será vendido o produto, logo abaixo, o valor e o número de transação.',
    step2:
      'Clique no botão “Simular” e informe o quanto de economia que o cliente terá ao pagar com Pix Braza.',
    step3:
      'Pronto! Clique no botão “Próximo” e siga os passos das próximas telas.',
  },
  qrcode: {
    title: 'Pagar em Reais (Pix)',
    goBack: 'Voltar para tela inicial',
    successPaymentTitle: 'Pagamento confirmado',
    success:
      'Informe ao cliente que o comprovante desta transação foi enviado para o seu e-mail.',
    expiredTitle: 'O tempo da sua transação expirou',
    expired:
      'Infelizmente, só podemos segurar a cotação por 15 minutos e o prazo expirou. <b>Realize a transação novamente.</b>',
    limitTransaction: 'O valor máximo por transação é de US$ 10,000.00',
    limitClient:
      'O limite do cliente foi atingido. O aumento de limite pode ser solicitado no aplicativo Braza',
    limitDaily:
      'O limite diário do cliente para este estabelecimento foi atingido',
    appMessage:
      'Leia o QR code abaixo para fazer o download do aplicativo Braza',
    new: 'Nova transação',
    price: 'Câmbio Comercial',
    cost: 'Spread Bancário',
    iof: 'IOF',
    timeLeft: 'Tempo restante: ',
    client: 'Cliente',
    doc: 'CPF',
    transactionId: 'Número de transação',
    titleDetailsSale: 'Detalhes do pagamento',
    seeDetails: 'Ver detalhes',
    cancelOperation: 'Cancelar compra',
    titleExitModal: 'Deseja sair dessa venda?',
    descriptionExitModal: 'Ao sair, todo esse processo atual de venda será perdido',
    continueExitModal: 'Continuar comprando',
    exitTextModal: 'Sair da venda'
  },
  brazilianResident: {
    question: 'Possui residência no Brasil?',
    infoBC:
      'De acordo com o Banco Central do Brasil (BCB), só será possível realizar operações de câmbio com CPF válido e endereço do Brasil.',
    yes: 'Residente no Brasil',
    no: 'Não residente no Brasil',
    notResidentMsg:
      'Infelizmente não é possível continuar com transação devido a falta de endereço no Brasil.',
    cancel: 'Cancelar e voltar',
  },
  register: {
    step: 'Passo',
    of: 'de',
    step1Title: 'Informe os dados pessoais do cliente',
    name: 'Nome Completo (sem abreviação)',
    invalid: 'Formato inválido',
    phone: 'Nº Celular',
    next: 'Continuar',
    step2Title: 'Informe os dados de endereço (Brasil)',
    state: 'Estado',
    city: 'Cidade',
    neighbor: 'Bairro',
    street: 'Endereço',
    number: 'Número',
    nonumber: 'Residência não possui número',
    cep: 'CEP',
    info: 'Complemento',
  },
  status: {
    success: 'Aprovada',
    pending: 'Em Andamento',
    fail: 'Cancelada',
    processed: 'Aprovada',
    success_tip:
      'Quando a venda ocorreu com sucesso e o valor já foi depositado na conta do Estabelecimento Comercial',
    expired_tip:
      'Quando o tempo de expiração do QR Code foi alcançado e o pagamento não ocorreu',
    fail_tip:
      'Quando o pagamento foi feito por uma pessoa diferente do CPF que foi cadastrado no sistema',
    pending_tip:
      'Quando o tempo de expiração do QR Code ainda não foi alcançado e o pagamento ainda não ocorreu',
    processed_tip:
      'Quando a venda ocorreu com sucesso. Isso significa que tanto o cadastro do usuário pagador foi aprovado quanto a geração e o pagamento da venda ocorreram com sucesso',
  },
  login: {
    title: 'Entrar - Braza Checkout',
    welcome: 'Boas-vindas ao Braza Checkout',
    access: 'Acessar minha conta',
    invalidEmail: 'E-mail inválido',
    password: 'Senha',
    invalidPassword: 'Senha inválida',
    invalidLogin:
      'E-mail ou senha incorretos. Não foi possível acessar a sua conta. Revise os campos e tente novamente.',
    login: 'Entrar',
    help: 'Ajuda',
  },
  menu: {
    home: 'Início',
    sell: 'Vender',
    faq: 'Ajuda',
    quit: 'Sair',
  },
  faq: {
    helpQuestion: 'Como podemos te ajudar?',
    placeholder: 'Pesquise aqui sua dúvida',
    phone: 'Telefone para contato',
    worktime: 'Horário de atendimento de segunda à sexta das 08h às 16h',
  },
};

import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BlueTextField from '../../components/BlueTextField';
import { doLogin } from '../../services/auth';
import { HOME_PAGE_PATH } from '../../utils/constants';
import { useSellerInfo } from '../../components/SellerInfo';

const Login = () => {
  const { t } = useTranslation('login');
  const { loadSellerInfo } = useSellerInfo()
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    document.title = t('title');
  }, []);

  const validateMailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    if (data.length < 5) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setLoginError(false);
    }
  };

  const validatePasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    if (typeof data === 'string' && data.length < 5) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      setLoginError(false);
    }
  };

  const handleSubmit = async () => {
    if (email.length < 5) {
      setEmailError(true);
      return;
    }

    if (password.length < 5) {
      setPasswordError(true);
      return;
    }

    setLoginError(false);
    setLoading(true);

    try {
      await doLogin(email, password);
      loadSellerInfo()
      navigate(HOME_PAGE_PATH);
    } catch (error) {
      setLoginError(true);
    }

    setLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url("/checkout-login.png")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        component={Paper}
        square
      />
      <Grid item xs={12} sm={8} md={5}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '400px',
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src="braza-logo.svg" alt="Braza" loading="lazy" />
            <Divider
              sx={{ width: '100%', color: 'text.disabled', marginTop: '40px' }}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                marginTop: '24px',
              }}
            >
              <Box
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '24px',
                  lineHeight: '29px',
                }}
              >
                {t('welcome')}
              </Box>
              <Typography sx={{ fontSize: '18px', fontWeight: 300 }}>
                {t('access')}
              </Typography>
            </Box>
            <Box sx={{ width: '100%', mt: 1 }}>
              <BlueTextField
                onInput={validateMailInput}
                error={emailError}
                sx={{ input: { color: 'primary.dark' } }}
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                label="E-mail"
                autoComplete="email"
                autoFocus
                variant="outlined"
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <Typography fontSize="0.75rem" color="error">
                  {t('invalidEmail')}
                </Typography>
              )}
              <BlueTextField
                onInput={validatePasswordInput}
                error={passwordError}
                sx={{
                  input: { color: 'primary.dark' },
                }}
                disabled={loading}
                margin="normal"
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t('password')}
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginRight: '0.3rem' }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <Typography fontSize="0.75rem" color="error">
                  {t('invalidPassword')}
                </Typography>
              )}
              {loginError && (
                <Typography
                  mt={2}
                  textAlign="center"
                  fontSize="0.75rem"
                  color="error"
                >
                  {t('invalidLogin')}
                </Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                disabled={loading || emailError || passwordError}
                sx={{
                  mt: '48px',
                  color: 'white',
                  bgcolor: 'primary.main',
                  fontSize: '18px',
                  fontWeight: '700',
                  py: '15px',
                  borderRadius: '6px',
                  textTransform: 'Capitalize',
                }}
                onClick={handleSubmit}
              >
                {!loading ? (
                  t('login')
                ) : (
                  <CircularProgress sx={{ color: 'white' }} />
                )}
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link
                  component="button"
                  sx={{
                    fontSize: '16px',
                    marginTop: '40px',
                    textDecoration: 'underline',
                    color: 'primary.main',
                    marginX: 'auto',
                  }}
                >
                  {t('help')}
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;

import { get, post } from './generic';

export async function getUserData() {
  try {
    const res = await get('/user');
    return res.data.data;
  } catch (error) {
    throw error;
  }
}

export async function validateCPF(cpf: string, quoteId: string) {
  try {
    const res = await post(`/client/validate`, {
      cpf,
      quotation_id: quoteId,
    });

    if (res.data.errors && res.data.errors.length) {
      throw new Error(res.data.errors[0].error);
    }

    return res.data.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Author: Angelo Reis <angelo.reis@3rd.braza.com.br>
 * Data da alteração: 11.04.2023
 * Alteração na resposta do endpoint. Agora a excessão e acionada
 * quando é encontrado erros na resposta vindo do bff
 * referente a task PAGA-692
 */
export async function doRegister(data: any) {
  const { data: dataResponse } = await post(`/register/create`, data);

  if (dataResponse.errors && dataResponse.errors.length) {
    throw new Error(dataResponse.errors[0].message);
  }

  return dataResponse.data;
}

import axios from 'axios';

function getInstance(useAuth: boolean) {
  const baseURL = process.env.REACT_APP_PUBLIC_API_ENDPOINT;
  const accessToken = localStorage.getItem('access_token');
  let headers = {};

  if (useAuth) {
    if (!accessToken) {
      throw new Error('Credenciais inválidas!');
    }
    headers = { Authorization: 'Bearer ' + accessToken };
  }

  return axios.create({
    baseURL,
    headers,
  });
}

export async function get(endpoint: string, useAuth = true) {
  const axios = getInstance(useAuth);

  try {
    const res = await axios.get(endpoint);
    return res;
  } catch (error: any) {
    throw error.response;
  }
}

export async function post(endpoint: string, data: any, useAuth = true) {
  const axios = getInstance(useAuth);

  try {
    const res = await axios.post(endpoint, data);
    return res;
  } catch (error: any) {
    throw error.response;
  }
}

export async function del(endpoint: string, useAuth = true) {
  const axios = getInstance(useAuth);

  try {
    const res = await axios.delete(endpoint);
    return res;
  } catch (error: any) {
    throw error.response;
  }
}

export async function put(endpoint: string, data: any, useAuth = true) {
  const axios = getInstance(useAuth);

  try {
    const res = await axios.put(endpoint, data);
    return res;
  } catch (error: any) {
    throw error.response;
  }
}

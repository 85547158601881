const zeroFill = (i: number) => {
  return (i < 10 ? '0' : '') + i;
}

const TransactionNumber = () => {
  const date = new Date();
  const fullYear = `${date.getFullYear()}${zeroFill(date.getMonth() + 1)}${zeroFill(date.getDate())}`
  const fullTime = `${zeroFill(date.getHours())}${zeroFill(date.getMinutes())}${zeroFill(date.getSeconds())}`

  return `${fullYear}${fullTime}${date.getMilliseconds()}`
}

export default TransactionNumber
import { ReactElement, ReactNode, createContext, useContext, useState } from 'react';

interface MenuContextType {
  opened: boolean
  handleCollapseMenu: () => void
  visibleLayout: string
  handleVisibleLayout: (visible: boolean) => void
  showBar: boolean
  handleShowBar: (visible: boolean) => void
}

export const MenuContext = createContext<MenuContextType>({
  opened: true,
  handleCollapseMenu: () => {},
  visibleLayout: 'none',
  handleVisibleLayout: () => {},
  showBar: true,
  handleShowBar: () => {}
});

type Props = {
  children: ReactNode;
};

export const MenuProvider = ({ children }: Props): ReactElement => {
  const [opened, setOpened] = useState(false);
  const [visibleLayout, setVisibleLayout] = useState('none');
  const [showBar, setShowBar] = useState(true)
  
  const handleCollapseMenu = () => setOpened(!opened)

  const handleVisibleLayout = (visible: boolean) => {
    visible ? setVisibleLayout('flex') : setVisibleLayout('none')
  }

  const handleShowBar = (visible: boolean) => setShowBar(visible)

  return (
    <MenuContext.Provider value={{ opened, handleCollapseMenu, visibleLayout, handleVisibleLayout, showBar, handleShowBar }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext)

import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type GoBackProps = {
  to: () => void;
};

const GoBack = (props: GoBackProps) => {
  const { to } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: {
          xs: 'left',
          sm: 'center',
        },
        color: 'text.secondary',
        cursor: 'pointer',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        py: '2rem',
        '@media print': {
          display: 'none'
        }
      }}
      onClick={to}
    >
      <ArrowBack
        sx={{
          height: '1.75rem',
          width: '1.75rem',
          mb: { xs: '1rem', sm: '0rem' },
          mr: '1.25rem',
        }}
      />
      <Typography fontSize="1.25rem">{t('goBack')}</Typography>
    </Box>
  );
};

export default GoBack;
